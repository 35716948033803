import React, { Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useToken } from 'app/hooks';
import { get } from 'lodash';
import { Routers } from 'app/constants'

import Spinner from '../app/shared/Spinner';

import Dashboard from 'app/dashboard/AdminDashboard';
import DashboardByReport from 'app/dashboard/AdminDashboardByReport';

import Buttons from './basic-ui/Buttons';
import Dropdowns from './basic-ui/Dropdowns';

import BasicElements from './form-elements/BasicElements';

import BasicTable from './tables/BasicTable';

import Mdi from './icons/Mdi';

import ChartJs from './charts/ChartJs';

import Error404 from './error-pages/Error404';
import Error500 from './error-pages/Error500';

import Login from './user-pages/Login';
import Register1 from './user-pages/Register';
import UserProfile from './profile';
import Notifycation from './notify';
import Guide from './guide';
import {
  ManagementUser,
  ManagementUserDetail,
  ManagementUserAdd,
  ManagementWorkSpace,
  ManagementWorkSpaceAdd,
  ManagementSendMessage
} from './admin-management';

import AdminFollowByUnit from 'app/dashboard/AdminFollowUnit';
import AdminViewer from 'app/reports/AdminViewer';

import NotifyDetail from 'app/notify/detail';

import Report from './reports';
import Report1 from './reports/report1';
import Report2 from './reports/report2';
import Report3 from './reports/report3';
import Report4 from './reports/report4';
import Report5 from './reports/report5';
import Report6 from './reports/report6';
import Report7 from './reports/report7';
import Report8 from './reports/report8';
import Report9 from './reports/report9';
import Report10 from './reports/report10';


function AppRoutes(props) {

  const { history, authState } = props
  const { token } = useToken();
  const [userRoleId, setUserRoleId] = useState();

  useEffect(() => {
    if (!token) {
      history.replace('/login')
    }
    const rolIDString = localStorage.getItem('roleID');
    const userRoleId = JSON.parse(rolIDString);
    const roleID = get(userRoleId, 'roleID');
    if (roleID) {
      setUserRoleId(roleID);
    }
    if (!roleID) {
      history.replace('/login')
    }
  }, [token, history]);


  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return (
            token ? (userRoleId === 'admin' ?
              <Redirect to="/dashboard" /> : <Redirect to={Routers.NOTIFICATION} />) : <Redirect to="/login" />
          )
        }}
      />
      <Route exact path="/report" component={Report} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/report/mau1" component={Report1} />
      <Route exact path="/report/mau2" component={Report2} />
      <Route exact path="/report/mau3" component={Report3} />
      <Route exact path="/report/mau4" component={Report4} />
      <Route exact path="/report/mau5" component={Report5} />
      <Route exact path="/report/mau6" component={Report6} />
      <Route exact path="/report/mau7" component={Report7} />
      <Route exact path="/report/mau8" component={Report8} />
      <Route exact path="/report/mau9" component={Report9} />
      <Route exact path="/report/mau10" component={Report10} />


      <Route path="/basic-ui/buttons" component={Buttons} />
      <Route path="/basic-ui/dropdowns" component={Dropdowns} />

      <Route path="/form-Elements/basic-elements" component={BasicElements} />

      <Route path="/tables/basic-table" component={BasicTable} />

      <Route path="/icons/mdi" component={Mdi} />

      <Route path="/charts/chart-js" component={ChartJs} />


      <Route path="/login" component={Login} />
      <Route path="/user-pages/register-1" component={Register1} />

      <Route path="/error-pages/error-404" component={Error404} />
      <Route path="/error-pages/error-500" component={Error500} />

      <Route path="/user/profile" component={UserProfile} />

      <Route exact path={Routers.ADMIN_USERS} component={ManagementUser} />
      <Route exact path={Routers.ADMIN_DASH_BOARD_BY_REPORT} component={DashboardByReport} />
      <Route exact path={Routers.ADMIN_USER_DETAIL} component={ManagementUserDetail} />
      <Route exact path={Routers.ADMIN_USER_ADD} component={ManagementUserAdd} />
      <Route exact path={Routers.ADMIN_WORK_SPACE} component={ManagementWorkSpace} />
      <Route exact path={Routers.ADMIN_WORK_SPACE_DETAIL} component={ManagementWorkSpaceAdd} />
      <Route exact path={Routers.FOLLOW_BY_UNIT} component={AdminFollowByUnit} />
      <Route exact path={Routers.ADMIN_REPORT_VIEW} component={AdminViewer} />
      <Route exact path={Routers.ADMIN_SEND_MESSAGE} component={ManagementSendMessage} />
      <Route exact path={Routers.NOTIFICATION} component={Notifycation} />
      <Route exact path={Routers.NOTIFICATION_DETAIL} component={NotifyDetail} />
      <Route exact path={Routers.GUIDE} component={Guide} />

      <Route component={Error404} />


      {/* <Redirect to="/login" /> */}
    </Switch>

  )

}

const mapStateToProps = state => (
  {
    authState: get(state, 'authUser'),
  }
);


const mapDispatchToProps = {

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRoutes))