import React, { useState } from 'react';
// import { Trans } from 'react-i18next';
import { get } from 'lodash';
import PropTypes from 'prop-types';


function SelectCustom(props) {
    const { options, onAdd, onDelelele, onChangeSelect, defaultValue = 0, canNotDeleteRow = true } = props || {};
    const [value, setValue] = useState(defaultValue);

    return (
        <div className="d-flex p-2 align-items-center">
            <select class="form-control auto-save-data-user select-country"
                style={{ width: "75%", display: "inline" }}
                value={value.toString()}
                onChange={(e) => {
                    const id = e.target.value;
                    onChangeSelect(id ? parseInt(id) : 0);
                    setValue(id)
                }}
            >
                {options.map(item => <option value={get(item, 'id')}>{get(item, 'name')}</option>)}
            </select>

            <button style={{ backgroundColor: 'transparent', borderWidth: 0, alignItems: 'center' }} onClick={() => onAdd()}>
                <i class="mdi mdi-hospital ml-1" style={{ color: '#094ae0', fontSize: '20px' }}></i>
            </button>

            {!canNotDeleteRow && <button style={{ backgroundColor: 'transparent', borderWidth: 0, alignItems: 'center' }} onClick={() => onDelelele()}>
                <i class="mdi mdi-close-box ml-1" style={{ color: '#eb4034', fontSize: '20px' }}></i>
            </button>}
        </div>

    );

}

export default SelectCustom;

SelectCustom.defaultProps = {
    onAdd: () => { },
    onDelelele: () => { },
    onChangeSelect: () => { },
    options: []
}

SelectCustom.propTypes = {
    onAdd: PropTypes.func.isRequired,
    onDelelele: PropTypes.func.isRequired,
    onChangeSelect: PropTypes.func.isRequired,
    canNotDeleteRow: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired,
}