export const API_URL = {
    DEV: 'http://localhost:5001/reporthccn/us-central1/app/api',
    PRD: 'https://us-central1-reporthccn.cloudfunctions.net/app/api'
};

export const APP_ENV = 'prd' //process.env.NODE_ENV; //enum('dev', 'prd);


export const KEY_SECURITY = 'KHCNQB';


export const firebaseConfig = {
    apiKey: "AIzaSyDk5ak8CooyCaNeUEKGEEyumV5m_-4jAjI",
    authDomain: "reporthccn.firebaseapp.com",
    projectId: "reporthccn",
    storageBucket: "reporthccn.appspot.com",
    messagingSenderId: "7632800330",
    appId: "1:7632800330:web:3fed4ee0e3359a7bda74b3"
  };