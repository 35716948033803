import React, { useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { getUserId } from 'app/utils';
import { actionGetAnalysis, actionGetUserMetadata } from 'app/redux/actions';
import { connect } from 'react-redux';
import Spinner from 'app/shared/Spinner';
import moment from 'moment';
import { DATA_REPORT_LIST } from 'app/constants';

function ReportDashboard(props) {

  const { actionGetAnalysis, history, location, reportsState, authState, actionGetUserMetadata, idReportsCanAccept } = props || {};
  const { isGetttingAnalysis } = reportsState || false;

  const { followUserId, year } = get(location, 'state', {});

  const userId = followUserId ? followUserId : getUserId();

  const [yearSelected, setYearSelected] = useState(year ? year : moment().year());
  const analysisByUserData = get(reportsState, 'analysis.data', {});
 
  if (!userId) {
    history.replace('/');
  }

  let analysis = [];



  DATA_REPORT_LIST.map(rp => {
    if (idReportsCanAccept.includes(rp.id) && analysisByUserData[rp.id]) {
      analysis.push({
        ...rp,
        ratio: get(analysisByUserData[rp.id], 'ratio', 0)
      })
    }
  });

  const onChangeYear = (e) => {
    const value = e.target.value;
    setYearSelected(e.target.value);
    actionGetAnalysis({ userId, year: value });

  };

  useEffect(() => {
    actionGetUserMetadata(userId);
    actionGetAnalysis({ userId, year: yearSelected });
  }, []);


  if (isGetttingAnalysis) {
    return <Spinner style={{ position: null }} />;
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Biểu mẫu báo cáo </h3>
      </div>
      <div className="row">

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">

              <div className="col-md-12 text-center" style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <div class="form-group d-flex p-2 align-center text-center" style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <label className="mr-2 align-center" for="controlYear">Chọn năm báo cáo</label>
                  <select class="form-control" id="controlYear" style={{ width: '120px' }}
                    value={yearSelected} onChange={(event) => onChangeYear(event)}>
                    <option value={2018}>2018</option>
                    <option value={2019}>2019</option>
                    <option value={2020}>2020</option>
                    <option value={2021}>2021</option>
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                  </select>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th><b>STT</b></th>
                      <th>Tên biểu mẫu </th>
                      <th>Tỉ lệ nhập liệu </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(analysis) && analysis.map((item, index) => (
                      <tr key={get(item, 'id')}>
                        <td>{index + 1}</td>
                        <td><Link className="nav-link"
                          to={{
                            pathname: `/report/mau${get(item, 'order')}`,
                            search: `?year=${yearSelected}`,
                            state: { idReport: get(item, 'id'), year: yearSelected }
                          }}>
                          {get(item, 'title')}
                        </Link>
                        </td>
                        <td><b className="text-primary">{(get(item, 'ratio', 1) * 100).toFixed(2)} % </b></td>
                        <td><Link className="nav-link"
                          to={{
                            pathname: `/report/mau${get(item, 'order')}`,
                            search: `?year=${yearSelected}`,
                            state: { idReport: get(item, 'id'), year: yearSelected }
                          }}><i className="mdi mdi-border-color"></i></Link></td>
                      </tr>))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportsState: get(state, 'reportState'),
  idReportsCanAccept: get(state, 'authState.userInfo.data.idReportsCanAccept', []),
});

const mapDispatchToProps = {
  actionGetAnalysis,
  actionGetUserMetadata
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportDashboard))
