import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { api } from 'app/utils';
import { get, isEmpty } from 'lodash';
import { getUserId } from 'app/utils';
import { actionLogOut, actionGetUserMetadata, actionGetWorkspace, actionGetAllRole, actionGetAllReports } from 'app/redux/actions';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useToken, useRouter } from 'app/hooks';
import Spinner from 'app/shared/Spinner';
import { Routers } from 'app/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { AdminWorlspaceSchema } from 'app/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

function ManagementWorkSpaceAdd(props) {
    const { history,
        authState,
        workSpaces,
        actionGetWorkspace,
        gettingWorkspace,
        listRoles,
        gettingRoles,
        actionGetAllRole,
        listReports,
        gettingReports,
        actionGetAllReports
    } = props;

    const routers = useRouter();
    const { location } = props || {}
    const workspace = get(location, 'state.workspace', {});

    const { setToken } = useToken();
    const [isLoading, setIsLoading] = useState(false);
    const [workSpaceSelected, setWorkSpaceSelected] = useState('');
    const [roleUserId, setRoleId] = useState('');

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(AdminWorlspaceSchema)
    });

    const [form, setForm] = useState({
        name: get(workspace, 'name', ''),
        email: get(workspace, 'email', ''),
        phoneNumber: get(workspace, 'phoneNumber', ''),
    });

    const onSubmmit = async () => {
        try {
            const isUpdate = isEmpty(workspace) ? false : true;

            setIsLoading(true);
            let res;
            if (isUpdate) {
                res = await api('admin/updateWorkSpace',
                    {
                        ...form,
                        userId: 'admin',
                        securityKey: 'KHCNQB',
                        updatedAt: moment(),
                        workSpaceId: get(workspace, 'id')
                    },
                );
            } else {
                res = await api('admin/addWorkSpace',
                    {
                        ...form,
                        userId: 'admin',
                        securityKey: 'KHCNQB',
                        created: moment()
                    });
            }

            if (res.data?.error) {
                toast.error("Đã có lỗi xảy ra", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.success("Cập nhật thành công!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    routers.push(Routers.ADMIN_WORK_SPACE)
                }, 2000)
            }

        } catch (e) {
            throw e;
        } finally {
            setIsLoading(false);
        }

    };


    const onChangeTextField = (field, event) => {
        setForm({ ...form, [field]: event.target.value })
    };


    const onDelete = async () => {
        try {
            setIsLoading(true);
            const res = await api('admin/deleteWorkSpace',
                {
                    ...form,
                    userId: 'admin',
                    securityKey: 'KHCNQB',
                    updatedAt: moment(),
                    workSpaceId: get(workspace, 'id')
                },
            );

            if (res.data?.error) {
                toast.error("Đã có lỗi xảy ra", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.success("Xoá thành công!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    routers.push(Routers.ADMIN_WORK_SPACE)
                }, 2000)
            }

        } catch (e) {
            throw e;
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        if (isEmpty(workSpaces)) {
            actionGetWorkspace('admin');
        }
        if (isEmpty(listRoles)) {
            actionGetAllRole('admin');
        }
        if (isEmpty(listReports)) {
            actionGetAllReports({ userId: 'admin' })
        }
    }, [])


    if (isLoading || gettingWorkspace || gettingRoles || gettingReports) {
        return <Spinner />
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Quản lí cơ quan/ đơn vị</h3>
            </div>
            <div className="row" style={{ backgroundColor: 'white' }}>
                <div className="col-md-8 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Nhập đầy đủ thông tin</h4>
                            <form className="forms-sample mt-4" onSubmit={handleSubmit(onSubmmit)}>

                                <div class="form-group"><label for="name">Tên đơn vị</label>
                                    <input placeholder="Tên đơn vị" type="text" id="name" class="form-control" aria-autocomplete="list"
                                        {...register("name")}
                                        value={form.name}
                                        onChange={event => onChangeTextField("name", event)}
                                    />
                                    <p class="invalid-validate">{errors.name?.message}</p>
                                </div>

                                <div class="form-group"><label for="email">Email</label>
                                    <input placeholder="Email" type="text" id="email" class="form-control" aria-autocomplete="list"
                                        value={form.email}
                                        {...register("email")}
                                        onChange={event => onChangeTextField("email", event)}
                                    />
                                    <p class="invalid-validate">{errors.email?.message}</p>
                                </div>

                                <div class="form-group"><label for="phoneNumber">SDT</label>
                                    <input placeholder="SDT" type="text" id="phoneNumber" class="form-control" aria-autocomplete="list"
                                        {...register("phoneNumber")}
                                        value={form.phoneNumber}
                                        onChange={event => onChangeTextField("phoneNumber", event)}
                                    />
                                    <p class="invalid-validate">{errors.phoneNumber?.message}</p>
                                </div>

                                <div className="d-flex">
                                    <div className="text-left">
                                        <button type="submit" className="btn btn-primary mr-2" style={{ width: 100, height: 50 }}>{isEmpty(workspace) ? 'Thêm' : 'Cập nhật'}</button>
                                    </div>
                                    <div className="text-right">
                                        {!isEmpty(workspace) && <button className="btn btn-danger mr-5" style={{ width: 100, height: 50, position: 'absolute', right: 0 }} onClick={onDelete}>Xoá</button>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    authState: get(state, 'authState'),
    userInfo: get(state, 'authState.userInfo.data', {}),
    workSpaces: get(state, 'authState.workSpaces.data', []),
    gettingWorkspace: get(state, 'authState.gettingWorkspace'),
    listRoles: get(state, 'authState.roles.data', []),
    gettingRoles: get(state, 'authState.gettingRoles'),
    listReports: get(state, 'authState.listReports.data', []),
    gettingReports: get(state, 'authState.gettingReports'),
});

const mapDispatchToProps = {
    actionLogOut,
    actionGetUserMetadata,
    actionGetWorkspace,
    actionGetAllRole,
    actionGetAllReports
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementWorkSpaceAdd))

