import React, { useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { actionGetAnalysis } from 'app/redux/actions';
import { connect } from 'react-redux';
import { getUserId } from 'app/utils';
import Spinner from 'app/shared/Spinner';
import moment from 'moment';
import { DATA_REPORT_LIST } from 'app/constants';

function ReportDashboard(props) {

  const { actionGetAnalysis, history, location, reportsState, } = props || {};
  const { followUserId, year, idReportsCanAccept } = get(location, 'state', {});

  const userId = followUserId ? followUserId : getUserId();
  const { isGetttingAnalysis } = reportsState || false;

  const analysisByUserData = get(reportsState, 'analysis.data', {});
  const [analysis, setAnalysis] = useState([]);

  useEffect(() => {
    let analysis = [];
    DATA_REPORT_LIST.map(rp => {
      if (idReportsCanAccept.includes(rp.id) && analysisByUserData[rp.id]) {
        analysis.push({
          ...rp,
          ratio: get(analysisByUserData[rp.id], 'ratio', 0)
        })
      }
    });
    setAnalysis(analysis);
  }, [analysisByUserData]);

  useEffect(() => {
    actionGetAnalysis({ userId, year });
  }, []);


  if (isGetttingAnalysis) {
    return <Spinner style={{ position: null }} />;
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Biểu mẫu báo cáo </h3>
      </div>
      <div className="row">

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">

              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th><b>STT</b></th>
                      <th>Tên biểu mẫu </th>
                      <th>Tỉ lệ nhập liệu </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(analysis) && analysis.map((item, index) => (
                      <tr key={get(item, 'id')}>
                        <td>{index + 1}</td>
                        <td><Link className="nav-link"
                          to={{
                            pathname: `/report/mau${get(item, 'order')}`,
                            search: `?year=${year}`,
                            state: { idReport: get(item, 'id'), year: year, followUserId: followUserId }
                          }}>
                          {get(item, 'title')}
                        </Link>
                        </td>
                        <td><b className="text-primary">{(get(item, 'ratio', 1) * 100).toFixed(2)} % </b></td>
                        <td><Link className="nav-link"
                          to={{
                            pathname: `/report/mau${get(item, 'order')}`,
                            search: `?year=${year}`,
                            state: { idReport: get(item, 'id'), year: year, followUserId: followUserId }
                          }}><i className="mdi mdi-border-color"></i></Link></td>
                      </tr>))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

const mapStateToProps = state => ({
  reportsState: get(state, 'reportState'),
});

const mapDispatchToProps = {
  actionGetAnalysis,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportDashboard))
