import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import {
  CommonTableHeaderReport,
  CommonTableFooterReport, HeaderExport,
  FooterUpload, inputStyle,
  SelectCustom
} from 'app/components';
import { get, isEmpty } from 'lodash';
import { getUserId } from 'app/utils';
import { connect } from 'react-redux';
import { actionGetStorageReportDetail } from 'app/redux/actions';
import { api } from 'app/utils';
import Spinner from 'app/shared/Spinner';
import parse from 'html-react-parser';
import shortid from 'shortid';
import { DATA_ECOMIC_MAJOR, TYPES_INPUT, DATA_COUNTIRES } from 'app/constants';
import * as FileSaver from 'file-saver';
import { useRouter } from 'app/hooks';
import { Routers } from 'app/constants'

function ReportDetail(props) {

  const { location, actionViewtemplate, reportState, tableData = [],
    idReportUser, isGetttingReportDetail, reportDetailTitle,
    reportDetailHeader, reportDetailFooter, idCanNotRemove, idDisabledInput } = props;
  const router = useRouter()

  const { idReport, year, followUserId } = get(location, 'state');
  const userId = followUserId ? followUserId : getUserId();

  if (!idReport) {
    router.push(Routers.REPORT);
  }

  const isLoadingUploadFile = get(reportState, 'isLoadingUploadFile');

  const [dynamicTableData, setDynamicTableData] = useState([]);
  const [exportting, setExportting] = useState(false);

  const [listidCanNotRemove, setListidCanNotRemove] = useState([]);

  const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();

  const onSubmit = async () => {
    const reqData = { userId, idReportUser, rawData: dynamicTableData };
    api('reports/updateDataSaved', reqData);
  }

  const onChangeTimeFooter = ({ dateValue, monthValue }) => {
    if (dateValue && monthValue)
      api('reports/updateDateMonthSaved', { userId, idReportUser, date: dateValue, month: monthValue });
  }

  const inputChange = async ({ event, id, idxRow, idxCol }) => {
    const valueText = event.target.value ? parseInt(event.target.value) : 0;
    let change = [...dynamicTableData];
    setValue(id, valueText);
    change[idxRow].rowCols[idxCol].value = valueText;

    const code = change[idxRow].rowCols[1].title;
    let setSumByCode = 0;
    change.map(row => {
      if (row.rowCols.find(col => col.type === 'code' && col.title === code)) {
        setSumByCode += parseInt(row.rowCols[3].value)
      }
    });
    if (code === '04') {
      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.title === '03'));
      if (!idxRowSum) return;
      setValue(change[idxRowSum].rowCols[3].id, setSumByCode);
      change[idxRowSum].rowCols[3].value = setSumByCode;
    }

    if (code === '14') {
      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.title === '13'));
      if (!idxRowSum) return;
      setValue(change[idxRowSum].rowCols[3].id, setSumByCode);
      change[idxRowSum].rowCols[3].value = setSumByCode;
    }

    if (code === '27') {
      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.title === '26'));
      if (!idxRowSum) return;
      setValue(change[idxRowSum].rowCols[3].id, setSumByCode);
      change[idxRowSum].rowCols[3].value = setSumByCode;
    }
    //save to DB
    setDynamicTableData(change);
    const reqData = { valueText, userId, idReportUser, rawData: change };
    api('reports/updateDataSaved', reqData);
  };

  const onAddRow = ({ idxRow, idxCol }) => {
    let newChanged = [...dynamicTableData];
    let rowAddData = { ...newChanged[idxRow] };
    rowAddData.rowCols = rowAddData.rowCols.map(item => {
      return { ...item, id: shortid.generate() }
    })
    newChanged.splice(idxRow + 1, 0, rowAddData);
    setDynamicTableData(newChanged);
    api('reports/updateDataSaved', { userId, idReportUser, rawData: newChanged });
  }

  const onDeleteRow = ({ idxRow, idxCol }) => {
    let newChanged = [...dynamicTableData];
    newChanged.splice(idxRow, 1);
    setDynamicTableData(newChanged);
    api('reports/updateDataSaved', { userId, idReportUser, rawData: newChanged });
  }

  const onRowChangeSelect = ({ idxRow, idxCol, newVal, type }) => {
    let newChanged = [...dynamicTableData];
    newChanged[idxRow].rowCols[idxCol].value = newVal;
    if (type == TYPES_INPUT.SELECT_COUNTRY) {
      const find = DATA_COUNTIRES.find(country => country.id == newVal) || DATA_COUNTIRES[0];
      newChanged[idxRow].rowCols[idxCol].title = get(find, 'name');
    }
    if (type == TYPES_INPUT.SELECT_MAJOR_EMOMIC) {
      const find = DATA_ECOMIC_MAJOR.find(country => country.id == newVal) || DATA_ECOMIC_MAJOR[0];
      newChanged[idxRow].rowCols[idxCol].title = get(find, 'name');
    }
    setDynamicTableData(newChanged);
    const reqData = { userId, idReportUser, rawData: newChanged };
    api('reports/updateDataSaved', reqData);
  }

  const onExportExcel = async () => {
    try {
      setExportting(true);
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const fileName = 'Bieu06_KHCN_CG';
      const response = await api('reports/exportExcel', {
        dataList: dynamicTableData,
        idReport: 'report6'
      });
      const buffer = get(response, 'data.buffer.data');
      const arr = new Uint8Array(buffer);
      const dataFile = new Blob([arr], { type: fileType });
      FileSaver.saveAs(dataFile, fileName + fileExtension);
    } catch (e) {
      console.log('onExportExcel error', e)
    }
    finally {
      setExportting(false);
    }
  }

  const analysisReport = async () => {
    try {
      setExportting(true);
      let sumInput = 0, sumTyped = 0;
      let ratio = 0;
      if (!dynamicTableData) return;
      dynamicTableData.map(row => {
        row.rowCols.map(col => {
          const { type, value } = col;
          if (type === 'input' && value > 0) {
            sumTyped++;
          }
          if (type === 'input') {
            sumInput++;
          }
        })
      });
      if (sumTyped !== 0){
        ratio = Number(sumTyped / sumInput);
      }
      const data = await api('reports/analysisByUser', {
        userId: userId,
        idReport: idReport,
        ratio: ratio,
        year: year
      });
      return data;
    } catch (e) {

    } finally {
      setExportting(false);
    }
  }

  const onFinishReport = async () => {
    await analysisReport();
    router.push("/report")
  }


  useEffect(() => {
    if (tableData) {
      setDynamicTableData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (idCanNotRemove) {
      setListidCanNotRemove(idCanNotRemove);
    }
  }, [idCanNotRemove]);

  useEffect(() => {
    actionViewtemplate({ idReport, year, userId });
    //analysisReport();
  }, []);


  if (isGetttingReportDetail || exportting || isLoadingUploadFile) {
    return <Spinner />
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> {reportDetailTitle}</h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <HeaderExport onExportExcel={() => onExportExcel()} />
              <div className="table-responsive">
                <CommonTableHeaderReport data={reportDetailHeader} title={reportDetailTitle} userId={userId} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1" width="45%"></td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">Mã số</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1" width="10%">Đơn vị tính</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">Tổng số</td>
                      </tr>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">A	</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">B</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">C</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">1</td>
                      </tr>

                      {dynamicTableData && dynamicTableData.map((row, idxRow) => {
                        return (
                          <tr key={idxRow}>
                            {get(row, 'rowCols', []).map((col, idxCol) => {
                              const { id, type } = col || {};
                              if (type === 'title') return (<td className="vertical-align" rowspan="1" colspan="1" >{parse(get(col, 'title'))}</td>);
                              if (type === 'code') return (<td className="vertical-align" rowspan="1" align="center" colspan="1"> {parse(get(col, 'title', ''))}</td>);
                              if (type === 'input') {
                                return (
                                  <td className="vertical-align" align="center" rowspan="1" colspan="1">
                                    <input type="number"
                                      readOnly={idDisabledInput.includes(id) || userId === 'admin' || followUserId}
                                      {...register(get(col, 'id'))}
                                      name={get(col, 'id')}
                                      min={0}
                                      id={get(col, 'id')}
                                      class="form-control-sm form-control form-input"
                                      style={inputStyle}
                                      defaultValue={get(col, 'value', 0)}
                                      onChange={event => inputChange({ event, id, idxRow, idxCol })}
                                    />
                                  </td>
                                )
                              }
                              if (type === TYPES_INPUT.SELECT_MAJOR_EMOMIC) {
                                return (
                                  <SelectCustom
                                    options={DATA_ECOMIC_MAJOR}
                                    onAdd={() => onAddRow({ idxRow, idxCol })}
                                    onDelelele={() => onDeleteRow({ idxRow, idxCol })}
                                    onChangeSelect={(newVal) => onRowChangeSelect({ idxRow, idxCol, newVal, type })}
                                    defaultValue={get(col, 'value')}
                                    canNotDeleteRow={listidCanNotRemove.includes(id)}
                                  />

                                )
                              }
                              if (type === TYPES_INPUT.SELECT_COUNTRY) {
                                return (
                                  <SelectCustom
                                    options={DATA_COUNTIRES}
                                    onAdd={() => onAddRow({ idxRow, idxCol })}
                                    onDelelele={() => onDeleteRow({ idxRow, idxCol })}
                                    onChangeSelect={(newVal) => onRowChangeSelect({ idxRow, idxCol, newVal, type })}
                                    defaultValue={get(col, 'value')}
                                    canNotDeleteRow={listidCanNotRemove.includes(id)}
                                  />

                                )
                              }
                              return (<td></td>);

                            })}
                          </tr>

                        )
                      })}
                      <tr>
                        <td className="vertical-align" rowspan="1" colspan="4"><i> Ghi theo cấp 1 của bảng Hệ thống ngành kinh tế Việt Nam theo Quyết định số 27/2018/QĐ-TTg về Hệ thống ngành kinh tế Việt Nam</i>	</td>
                      </tr>
                    </tbody>
                  </table>
                </form>
                <CommonTableFooterReport data={reportDetailFooter} onChangeTime={onChangeTimeFooter} />
                <FooterUpload onFinishReport={onFinishReport} storageID={idReportUser}
                  fileUploaded={get(reportState, 'reportDetail.data.fileUpload')}
                  onFinishUpload={() => actionViewtemplate({ idReport, year, userId })}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportState: get(state, 'reportState'),
  tableData: get(state, 'reportState.reportDetail.data.rawData', []),
  sizeCols: get(state, 'reportState.reportDetail.data.size', 1),
  idCanNotRemove: get(state, 'reportState.reportDetail.data.idCanNotRemove', []),
  idDisabledInput: get(state, 'reportState.reportDetail.data.idDisabledInput', []),
  idReportUser: get(state, 'reportState.reportDetail.data.id'),
  isGetttingReportDetail: get(state, 'reportState.isGetttingReportDetail'),
  reportDetailHeader: get(state, 'reportState.reportDetail.data.headers', {}),
  reportDetailTitle: get(state, 'reportState.reportDetail.data.title', ''),
  reportDetailFooter: get(state, 'reportState.reportDetail.data.footer', {}),

});

const mapDispatchToProps = {
  actionViewtemplate: actionGetStorageReportDetail
};


export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail)
