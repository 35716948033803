import { ActionsType } from 'app/redux/actions';
import { api } from 'app/utils';
import { get } from 'lodash';

export const actionLogin = reqData => async dispatch => {
    dispatch({ type: ActionsType.LOGIN_USER_REQUEST })

    try {
        const res = await api('auth', reqData, true);
        const { data } = res || {};
        if (get(data, 'data')) {
            dispatch({ type: ActionsType.LOGIN_USER_SUCCESS, payload: data });
        } else {
            dispatch({ type: ActionsType.LOGIN_USER_FAIL, payload: data });
        }
        return res;
    } catch (ex) {
        dispatch({ type: ActionsType.LOGIN_USER_FAIL, payload: ex.message });
        throw ex;
    }
};

export const actionLogOut = () => async dispatch => {
    dispatch({ type: ActionsType.LOGOUT_USER });
    localStorage.clear();
};

export const actionGetUserMetadata = id => async dispatch => {
    dispatch({ type: ActionsType.GET_USER_META_REQUEST })

    try {
        const res = await api(`auth/userMetadata?idUser=${id}`, null, true);
        const { data } = res || {};
        if (get(data, 'data')) {
            dispatch({ type: ActionsType.GET_USER_META_SUCCESS, payload: data });
        } else {
            dispatch({ type: ActionsType.GET_USER_META_FAIL, payload: data });
        }
        return res;
    } catch (ex) {
        dispatch({ type: ActionsType.GET_USER_META_FAIL, payload: ex.message });
        throw ex;
    }
};


export const actionGetWorkspace = id => async dispatch => {
    dispatch({ type: ActionsType.GET_WORK_SPACE_REQUEST })

    try {
        const res = await api(`auth/workSpaces?userId=${id}`, null, true);
        const { data } = res || {};
        if (get(data, 'data')) {
            dispatch({ type: ActionsType.GET_WORK_SPACE_SUCCESS, payload: data });
        } else {
            dispatch({ type: ActionsType.GET_WORK_SPACE_FAIL, payload: data });
        }
        return res;
    } catch (ex) {
        dispatch({ type: ActionsType.GET_WORK_SPACE_FAIL, payload: ex.message });
        throw ex;
    }
};

export const actionGetAllRole = id => async dispatch => {
    dispatch({ type: ActionsType.GET_ROLES_REQUEST })

    try {
        const res = await api(`auth/getRoles?userId=${id}`, null, true);
        const { data } = res || {};
        if (get(data, 'data')) {
            dispatch({ type: ActionsType.GET_ROLES_SUCCESS, payload: data });
        } else {
            dispatch({ type: ActionsType.GET_ROLES_FAIL, payload: data });
        }
        return res;
    } catch (ex) {
        dispatch({ type: ActionsType.GET_ROLES_FAIL, payload: ex.message });
        throw ex;
    }
};


export const actionGetAllReports = reqData => async dispatch => {
    const { userId } = reqData || '';
    dispatch({ type: ActionsType.GET_ALL_REPORTS_REQUEST })

    try {
        const res = await api(`auth/getAllReports?userId=${userId}`);
        const { data } = res || {};
        if (get(data, 'data')) {
            dispatch({ type: ActionsType.GET_ALL_REPORTS_SUCCESS, payload: data });
        } else {
            dispatch({ type: ActionsType.GET_ALL_REPORTS_FAIL, payload: data });
        }
        return res;
    } catch (ex) {
        dispatch({ type: ActionsType.GET_ALL_REPORTS_FAIL, payload: ex.message });
        throw ex;
    }
};