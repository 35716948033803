import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input';
import { api } from 'app/utils';
import { get, isEmpty } from 'lodash';
import { getUserId } from 'app/utils';
import { actionLogOut, actionGetUserMetadata, actionGetWorkspace, actionGetAllRole, actionGetAllReports } from 'app/redux/actions';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useToken, useRouter } from 'app/hooks';
import Spinner from 'app/shared/Spinner';
import { Routers } from 'app/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { AdminSchema } from 'app/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

function ManagementUserDetail(props) {
    const { history,
        authState,
        workSpaces,
        actionGetWorkspace,
        gettingWorkspace,
        listRoles,
        gettingRoles,
        actionGetAllRole,
        listReports,
        gettingReports,
        actionGetAllReports,
        location
    } = props;

    const { userDetail } = get(location, 'state');
    const userId = get(userDetail, 'userId');

    const routers = useRouter();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(AdminSchema)
    });

    const { setToken } = useToken();
    const [isLoading, setIsLoading] = useState(false);
    const [workSpaceSelected, setWorkSpaceSelected] = useState(get(userDetail, 'workSpaceID', ''));
    const [roleUserId, setRoleId] = useState(get(userDetail, 'roleID', ''));

    const [form, setForm] = useState({
        fullName: get(userDetail, 'fullName', ''),
        email: get(userDetail, 'email', ''),
        phoneNumber: get(userDetail, 'phoneNumber', ''),
        userName: get(userDetail, 'userName', ''),
        roleID: get(userDetail, 'roleID', ''),
        workSpaceID: get(userDetail, 'workSpaceID', ''),
    });

    const [idAcceptReport, setIdAcceptReport] = useState(get(userDetail, 'idReportsCanAccept', []));

    const onUpdate = async () => {
        try {
            setIsLoading(true);
            const res = await api('admin/updateUser',
                {
                    ...form,
                    workSpaceID: workSpaceSelected,
                    roleID: roleUserId,
                    userId: userId,
                    idReportsCanAccept: idAcceptReport
                },
            );
            if (res.data?.error) {
                toast.error("Đã có lỗi xảy ra", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.success("Cập nhật thông tin tài khoản thành công!", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    routers.push(Routers.ADMIN_USERS)
                }, 1000)
            }

        } catch (e) {
            throw e;
        } finally {
            setIsLoading(false);
        }

    };

    const onResetPass = async (e) => {
        try {
            const newPass = window.prompt("Mật khẩu mới");
            if (!newPass) {
                toast.error("Đặt lại mật khẩu mới không được để trống", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                e.preventDefault();
                return false;
            }

            setIsLoading(true);
            const res = await api('admin/resetPassword',
                {
                    userId: userId,
                    newPassword: newPass
                },
            );
            if (res.data?.error) {
                toast.error("Đã có lỗi xảy ra", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.success("Đặt lại mật khẩu thành công!", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    routers.push(Routers.ADMIN_USERS)
                }, 1000)
            }

        } catch (e) {
            throw e;
        } finally {
            setIsLoading(false);
        }
    }

    const onDelete = async () => {
        try {
            setIsLoading(true);

            const res = await api('admin/deleteUser',
                {
                    userId: 'admin',
                    idUserDelete: userId,
                    securityKey: 'KHCNQB',
                }
            );
            if (res.data?.error) {
                toast.error("Đã có lỗi xảy ra", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.success("Xoá tài khoản thành công!", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    routers.push(Routers.ADMIN_USERS)
                }, 1000)
            }

        } catch (e) {
            throw e;
        } finally {
            setIsLoading(false);
        }
    }

    const onChangeTextField = (field, event) => {
        setForm({ ...form, [field]: event.target.value })
    };

    const onChangeWorkSpace = (event) => {
        setWorkSpaceSelected(event.target.value);
    }

    const onChangeRole = (event) => {
        setRoleId(event.target.value);
    }

    const onChangeAcceptReport = (event, id) => {
        const value = event.target.checked;
        let array = [...idAcceptReport];
        if (value === true) {
            if (idAcceptReport.indexOf(id === -1)) {
                array.push(id);
                setIdAcceptReport(array);
            }
        } else {
            if (idAcceptReport.includes(id)) {
                const idx = idAcceptReport.indexOf(id);
                if (idx !== -1) {
                    array.splice(idx, 1);
                    setIdAcceptReport(array);
                }
            }
        }
    }

    const onConfirmDelete = (event) => {
        event.preventDefault();
        const confirm = window.confirm('Chắc chắn muốn xoá tài khoản này?');
        if (confirm === true) {
            return onDelete();
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (isEmpty(userDetail)) {
            routers.push(Routers.ADMIN_USERS)
        }
        if (isEmpty(workSpaces)) {
            actionGetWorkspace(userId);
        }
        if (isEmpty(listRoles)) {
            actionGetAllRole(userId);
        }
        if (isEmpty(listReports)) {
            actionGetAllReports({ userId: 'admin' })
        }
    }, [])


    if (isLoading || gettingWorkspace || gettingRoles || gettingReports) {
        return <Spinner />
    }


    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Quản lí tài khoản người dùng</h3>
            </div>
            <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">Chỉnh sửa thông tin</h3>
                            <form className="forms-sample mt-4"
                            // onSubmit={handleSubmit(onUpdate)}
                            >

                                <div class="form-group"><label for="fullName">Họ và Tên</label>
                                    <input placeholder="Họ tên / đơn vị" type="text" id="fullName" class="form-control" aria-autocomplete="list"
                                        {...register("fullName")}
                                        value={form.fullName}
                                        onChange={event => onChangeTextField("fullName", event)}
                                    />
                                    <p class="invalid-validate">{errors.fullName?.message}</p>
                                </div>

                                <div class="form-group"><label for="userName">Tài khoản đăng nhập</label>
                                    <input placeholder="Tên đăng nhập" type="text" id="userName" class="form-control" aria-autocomplete="list" disabled
                                        {...register("userName")}
                                        value={form.userName}
                                        onChange={event => onChangeTextField("userName", event)}
                                    />
                                    <p class="invalid-validate">{errors.userName?.message}</p>
                                </div>

                                <div class="form-group"><label for="email">Email</label>
                                    <input placeholder="Email" type="text" id="email" class="form-control" aria-autocomplete="list"
                                        {...register("email")}
                                        value={form.email}
                                        onChange={event => onChangeTextField("email", event)}
                                    />
                                    <p class="invalid-validate">{errors.email?.message}</p>
                                </div>

                                <div class="form-group"><label for="phone">SDT</label>
                                    <input placeholder="SDT" type="text" id="phone" class="form-control" aria-autocomplete="list"
                                        {...register("phoneNumber")}
                                        value={form.phoneNumber}
                                        onChange={event => onChangeTextField("phoneNumber", event)}
                                    />
                                    <p class="invalid-validate">{errors.phoneNumber?.message}</p>
                                </div>

                                <div class="form-group"> <label htmlFor="workSpaces">Cơ quan/Đơn vị</label>
                                    <select className="form-control form-control-lg" id="workSpaces"
                                        // {...register("workSpace")}
                                        value={workSpaceSelected}
                                        onChange={(event) => onChangeWorkSpace(event)}>
                                        <option value="">-Chọn cơ quan/ đơn vị </option>
                                        {workSpaces.map(item => (
                                            <option value={get(item, 'id')}>{get(item, 'name')}</option>
                                        ))}
                                    </select>
                                    {/* <p class="invalid-validate">{errors.workSpace?.message}</p> */}
                                </div>

                                <div class="form-group">  <label htmlFor="roles">Phân quyền</label>
                                    <select className="form-control form-control-lg" id="workSpaces"
                                        // {...register("roleId")}
                                        value={roleUserId}
                                        onChange={(event) => onChangeRole(event)}>
                                        <option value="">-Chọn một quyền</option>
                                        {listRoles.map(item => (
                                            <option value={get(item, 'id')}>{get(item, 'desc')}</option>
                                        ))}
                                    </select>
                                    {/* <p class="invalid-validate">{errors.roleId?.message}</p> */}
                                </div>

                                <div className="d-flex mt-3">
                                    <div className="text-left">
                                        <button type="submit" className="btn btn-primary mr-2" style={{ width: 100, height: 50 }} onClick={() => onUpdate()}>Cập nhật</button>
                                    </div>

                                    <div className="text-left ml-2">
                                        <button type="submit" className="btn btn-warning mr-2" style={{ width: 135, height: 50 }} onClick={(e) => onResetPass(e)}>Đặt lại mật khẩu</button>
                                    </div>

                                    <div className="text-right">
                                        <button className="btn btn-danger mr-3" style={{ width: 130, height: 50, position: 'absolute', right: 0 }} onClick={(event) => onConfirmDelete(event)}>Xoá tài khoản</button>
                                    </div>
                                </div>

                            </form>


                        </div>
                    </div>
                </div>

                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Phân quyền truy cập báo cáo</h4>
                            <p className="card-description">Click chọn các báo cáo mà tài khoản có thể nhập</p>
                            <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form.Group>
                                            {listReports && listReports.map(report => {
                                                const { id } = report;
                                                const checked = idAcceptReport.includes(id);
                                                return (
                                                    <div className="form-check mt-4 form-check-primary">
                                                        <label className="form-check-label">
                                                            <input type="checkbox" className="form-check-input" defaultChecked={checked} value={checked} onChange={event => onChangeAcceptReport(event, id)} />
                                                            <i className="input-helper"></i>
                                                            {get(report, 'title')}
                                                        </label>
                                                    </div>
                                                )
                                            })}

                                        </Form.Group>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    authState: get(state, 'authState'),
    userInfo: get(state, 'authState.userInfo.data', {}),
    workSpaces: get(state, 'authState.workSpaces.data', []),
    gettingWorkspace: get(state, 'authState.gettingWorkspace'),
    listRoles: get(state, 'authState.roles.data', []),
    gettingRoles: get(state, 'authState.gettingRoles'),
    listReports: get(state, 'authState.listReports.data', []),
    gettingReports: get(state, 'authState.gettingReports'),
});

const mapDispatchToProps = {
    actionLogOut,
    actionGetUserMetadata,
    actionGetWorkspace,
    actionGetAllRole,
    actionGetAllReports
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementUserDetail))
