import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { actionGetUserMetadata } from 'app/redux/actions';
import { Routers } from 'app/constants';

function Sidebar(props) {
  const { authState, location } = props;
  const [userInfo, setUserInfo] = useState(get(authState, 'userInfo.data'));
  const [state, setState] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach(i => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  };

  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(state).forEach(i => {
      setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/form-elements', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (isPathActive(obj.path)) {
        setState({ [obj.state]: true })
      }
    }));

  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  useEffect(() => {
    const userInfo = get(authState, 'userInfo.data');
    if (userInfo) {
      setUserInfo(userInfo);
      const isAdmin = get(userInfo, 'roleID', '') === 'admin' ? true : false;
      setIsAdmin(isAdmin);
    } else {
      const userIdString = localStorage.getItem('userId');
      if (userIdString) {
        const { userId } = JSON.parse(userIdString) || null;
        if (userId) {
          actionGetUserMetadata(userId);
        }
      }
    }

  }, [authState]);

  useEffect(() => {
    onRouteChanged();
    const rolIDString = localStorage.getItem('roleID');
    const userRoleId = JSON.parse(rolIDString);
    const roleID = get(userRoleId, 'roleID');
    setIsAdmin(roleID === 'admin' ? true : false);

    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });



  }, []);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
        {/* <a className="sidebar-brand brand-logo" href="/dashboard"><img src={require("../../assets/images/logo.png")} alt="logo" /></a> */}
        <a className="sidebar-brand brand-logo" href={isAdmin ? "/dashboard" : "/report"}>
          <h4><span><i class="mdi mdi-home" style={{ color: '#FFFFFF', fontSize: '26px', marginRight: '4px' }}></i></span><b className="text-white">Sở KH&CN Quảng Bình</b></h4>
        </a>
        <a className="sidebar-brand brand-logo-mini pt-3" href={isAdmin ? "/dashboard" : "/report"}><img src={require("../../assets/images/logo-mini.svg")} alt="logo" /></a>
      </div>
      <ul className="nav">
        <li className="nav-item nav-profile not-navigation-link">
          <div className="p-3" style={{ borderBottomWidth: 0.5, borderBottomColor: 'white', borderBottomStyle: 'solid' }}>
            <div className="d-flex justify-content-between align-items-start">
              <div className="profile-image">
                <img className="img-xs rounded-circle" src={require("../../assets/images/faces/facePlaceHolder.png")} alt="profile" />
                {/* <div className="dot-indicator bg-success"></div> */}
              </div>
              <div className="text-left text-break ml-2" style={{ marginBottom: 0 }}>
                <small className="designation text-left text-white">{get(userInfo, 'userName')}</small>

                <p className="profile-name text-left text-white" style={{ overflowWrap: 'break-word' }}>
                  {get(userInfo, 'fullName')}
                </p>

              </div>
            </div>
          </div>
        </li>


        <li className={isPathActive(Routers.NOTIFICATION) ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={Routers.NOTIFICATION}>
            <i className="mdi mdi-bell-ring menu-icon"></i>
            <span className="menu-title"><Trans>Thông báo</Trans></span>
          </Link>
        </li>

        <li className={isPathActive('/report') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/report">
            <i className="mdi mdi-newspaper menu-icon"></i>
            <span className="menu-title"><Trans>{isAdmin ? 'Tổng hợp nhập liệu' : 'Nhập biểu mẫu'}</Trans></span>
          </Link>
        </li>


        {isAdmin &&
          <li className={isPathActive(Routers.FOLLOW_BY_UNIT) ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to={Routers.FOLLOW_BY_UNIT}>
              <i className="mdi mdi-calendar-clock menu-icon"></i>
              <span className="menu-title"><Trans>Theo dõi tiến độ</Trans></span>
            </Link>
          </li>}

        {isAdmin &&
          <li className={isPathActive(Routers.ADMIN_DASH_BOARD_BY_REPORT) ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to={Routers.ADMIN_DASH_BOARD_BY_REPORT}>
              <i className="mdi mdi-book-open-variant menu-icon"></i>
              <span className="menu-title"><Trans>Thống kê theo biểu</Trans></span>
            </Link>
          </li>}

        {isAdmin &&
          <li className={isPathActive(Routers.ADMIN_USERS) ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to={Routers.ADMIN_USERS}>
              <i className="mdi mdi-account-search menu-icon"></i>
              <span className="menu-title"><Trans>Quản trị tài khoản</Trans></span>
            </Link>
          </li>}


        {isAdmin &&
          <li className={isPathActive(Routers.ADMIN_WORK_SPACE) ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to={Routers.ADMIN_WORK_SPACE}>
              <i className="mdi mdi mdi-book-variant menu-icon"></i>
              <span className="menu-title"><Trans>Quản trị đơn vị</Trans></span>
            </Link>
          </li>}


        {isAdmin &&
          <li className={isPathActive(Routers.ADMIN_SEND_MESSAGE) ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to={Routers.ADMIN_SEND_MESSAGE}>
              <i className="mdi mdi mdi-email menu-icon"></i>
              <span className="menu-title"><Trans>Gửi thông báo</Trans></span>
            </Link>
          </li>}

        {isAdmin &&
          <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-chart-line menu-icon"></i>
              <span className="menu-title"><Trans>Báo cáo, thống kê</Trans></span>
            </Link>
          </li>}

          <li className={isPathActive(Routers.GUIDE) ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to={Routers.GUIDE}>
              <i className="mdi mdi-book-open-variant menu-icon"></i>
              <span className="menu-title"><Trans>Tài liệu hướng dẫn</Trans></span>
            </Link>
          </li>


{/* 
        <li className={isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
          <div className={state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            <span className="menu-title"><Trans>Basic UI Elements</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.basicUiMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link'} to="/basic-ui/buttons"><Trans>Buttons</Trans></Link></li>
              <li className="nav-item"> <Link className={isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link'} to="/basic-ui/dropdowns"><Trans>Dropdowns</Trans></Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/form-elements') ? 'nav-item active' : 'nav-item'}>
          <div className={state.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('formElementsMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-format-list-bulleted menu-icon"></i>
            <span className="menu-title"><Trans>Form Elements</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.formElementsMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/form-elements/basic-elements') ? 'nav-link active' : 'nav-link'} to="/form-elements/basic-elements"><Trans>Basic Elements</Trans></Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/tables') ? 'nav-item active' : 'nav-item'}>
          <div className={state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('tablesMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-table-large menu-icon"></i>
            <span className="menu-title"><Trans>Tables</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.tablesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/tables/basic-table') ? 'nav-link active' : 'nav-link'} to="/tables/basic-table"><Trans>Basic Table</Trans></Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/icons') ? 'nav-item active' : 'nav-item'}>
          <div className={state.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('iconsMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-account-box-outline menu-icon"></i>
            <span className="menu-title"><Trans>Icons</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.iconsMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/icons/mdi') ? 'nav-link active' : 'nav-link'} to="/icons/mdi">Material</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/charts') ? 'nav-item active' : 'nav-item'}>
          <div className={state.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('chartsMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-chart-line menu-icon"></i>
            <span className="menu-title"><Trans>Charts</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.chartsMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/charts/chart-js') ? 'nav-link active' : 'nav-link'} to="/charts/chart-js">Chart Js</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/user-pages') ? 'nav-item active' : 'nav-item'}>
          <div className={state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('userPagesMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-lock-outline menu-icon"></i>
            <span className="menu-title"><Trans>User Pages</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.userPagesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link'} to="/user-pages/login-1"><Trans>Login</Trans></Link></li>
              <li className="nav-item"> <Link className={isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link'} to="/user-pages/register-1"><Trans>Register</Trans></Link></li>
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/error-pages') ? 'nav-item active' : 'nav-item'}>
          <div className={state.errorPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('errorPagesMenuOpen')} data-toggle="collapse">
            <i className="mdi mdi-information-outline menu-icon"></i>
            <span className="menu-title"><Trans>Error Pages</Trans></span>
            <i className="menu-arrow"></i>
          </div>
          <Collapse in={state.errorPagesMenuOpen}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/error-pages/error-404') ? 'nav-link active' : 'nav-link'} to="/error-pages/error-404">404</Link></li>
              <li className="nav-item"> <Link className={isPathActive('/error-pages/error-500') ? 'nav-link active' : 'nav-link'} to="/error-pages/error-500">500</Link></li>
            </ul>
          </Collapse>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="http://www.bootstrapdash.com/demo/star-admin-free/react/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
            <i className="mdi mdi-file-outline menu-icon"></i>
            <span className="menu-title"><Trans>Documentation</Trans></span>
          </a>
        </li> */}

      </ul>
    </nav >
  );

}

const mapStateToProps = state => ({
  authState: get(state, 'authState'),
});

const mapDispatchToProps = {
  actionGetUserMetadata
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));