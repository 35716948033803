import {
  ActionsType
} from '../actions';

export const reportReducer = (state = {}, action) => {
  const { type, payload } = action || {};
  switch (type) {
    case ActionsType.GET_ANALYSIS_REQUEST: {
      return {
        ...state,
        isGetttingAnalysis: true,
        analysis: []
      }
    }
    case ActionsType.GET_ANALYSIS_SUCCESS: {
      return {
        ...state,
        isGetttingAnalysis: false,
        analysis: payload
      }
    }
    case ActionsType.GET_ANALYSIS_FAIL: {
      return {
        ...state,
        isGetttingAnalysis: false,
        errorAnalysis: payload
      }
    }
    case ActionsType.GET_STORAGE_REPORT_DETAIL_REQUEST: {
      return {
        ...state,
        isGetttingReportDetail: true,
        reportDetail: []
      }
    }
    case ActionsType.GET_STORAGE_REPORT_DETAIL_SUCCESS: {
      return {
        ...state,
        isGetttingReportDetail: false,
        reportDetail: payload
      }
    }
    case ActionsType.GET_STORAGE_REPORT_DETAIL_FAIL: {
      return {
        ...state,
        isGetttingReportDetail: false,
        errorReportDetail: payload
      }
    }

    case ActionsType.LOADING_UPLOAD_FILE: {
      return {
        ...state,
        isLoadingUploadFile: true,
      }
    }

    case ActionsType.ENDED_LOADING_UPLOAD_FILE: {
      return {
        ...state,
        isLoadingUploadFile: false,
      }
    }

    default: return state;
  }
};


export default reportReducer;
