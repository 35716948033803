import React, { useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { actionGetAnalysis, actionGetWorkspace } from 'app/redux/actions';
import { connect } from 'react-redux';
import Spinner from 'app/shared/Spinner';
import moment from 'moment';
import { DATA_REPORT_LIST, Routers, DATE_FORMAT_DD_MM_YYY } from 'app/constants';
import { getUserId } from 'app/utils';
import { api } from 'app/utils';
import { KEY_SECURITY } from 'app/configs';
import { useRouter } from 'app/hooks';

function ManagementWorkSpace(props) {
  const { gettingWorkspace, actionGetWorkspace, workSpaces } = props;

  const routers = useRouter();

  const onAddUser = () => {
    routers.push(Routers.ADMIN_WORK_SPACE_DETAIL, { workspace: {} });
  }


  useEffect(() => {
    // fetchUsers();
    actionGetWorkspace();
  }, []);

  if (gettingWorkspace) {
    return (
      <Spinner />
    )
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Danh sách cơ quan/đơn vị</h2>
          <div className="table-responsive">
            <table className="table table-bordered bordered">
              <thead>
                <tr>
                  <th> # </th>
                  <th>Tên đơn vị  </th>
                  <th> Số điện thoại </th>
                  <th> Email </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>

                {!isEmpty(workSpaces) && workSpaces.map((item, idx) => {
                  return (
                    <tr>
                      <td> {idx + 1}</td>
                      <td><Link className="nav-link"
                        to={{
                          pathname: Routers.ADMIN_WORK_SPACE_DETAIL,
                          state: { workspace: item }
                        }}><p className="text-primary">{get(item, 'name', '')}</p></Link> </td>
                      <td><p className="text-primary">{get(item, 'phoneNumber', '')}</p></td>
                      <td><p className="text-primary">{get(item, 'email', '')}</p></td>
                      <td> <Link className="nav-link"
                        to={{
                          pathname: Routers.ADMIN_WORK_SPACE_DETAIL,
                          state: { workspace: item }
                        }}> <i class="mdi mdi-eye text-primary"></i> </Link></td>
                    </tr>
                  )
                })}

              </tbody>
            </table>

            <button type="submit" className="btn btn-primary mt-3" style={{ width: 150, height: 50 }} onClick={() => onAddUser()}>Thêm tài khoản</button>

          </div>
        </div>
      </div>
    </div>
  )


}

const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportsState: get(state, 'reportState'),
  workSpaces: get(state, 'authState.workSpaces.data', []),
  gettingWorkspace: get(state, 'authState.gettingWorkspace'),
});

const mapDispatchToProps = {
  actionGetWorkspace
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementWorkSpace))
