import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL, APP_ENV } from 'app/configs';
import { get } from 'lodash'

const api = async function (path, data = null, showError = false) {
    try {
        let res;
        const url = APP_ENV === 'dev' ? `${API_URL.DEV}/${path}` : `${API_URL.PRD}/${path}`;

        if (data) {
            res = await axios.post(url, data);

        } else {
            res = await axios.get(url, {
                params: data
            })
        }

        if (showError && get(res, 'data.error')) {
            const message = get(res, 'data.error.message', 'Đã có lỗi xảy ra!');
            toast.error(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        return res;
    } catch (err) {
        console.log({ err })
    }

};

export default api;
