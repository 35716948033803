import React, { Component,useEffect } from 'react';
// import { Trans } from 'react-i18next';
import { actionLogOut, actionGetUserMetadata, actionGetWorkspace } from 'app/redux/actions';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';


function TableHeader(props) {
    const { data, title ,userId,userInfo,actionGetUserMetadata} = props;
    const { unit, left, right, center } = data || {};

    useEffect(() => {
        actionGetUserMetadata(userId);
    },[]);


    return (
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <td className="vertical-align" align="left" rowspan="1" colspan="1" width="30%">
                        <div><b>{title}</b> <br /></div>
                        <div>Ban hành kèm theo Thông tư số 15/2018/TT-BKHCN ngày 15 tháng 11 năm 2018 <br /></div>
                        <div>Ngày nhận báo cáo: Ngày 15/02 hằng năm</div>
                    </td>
                    <td className="vertical-align" align="center" rowspan="1" colspan="3">
                        <div><b>{center}</b> <br /></div>
                        <div>(Có đến ngày 31/12)</div>
                    </td>
                    <td className="vertical-align" align="left" rowspan="1" colspan="3" width="30%">
                        <div><b>- Đơn vị báo cáo:</b> <br /></div>
                        <div>{`${userInfo? userInfo?.fullName: ''}`}<br /></div>
                        <div><b>- Đơn vị nhận báo cáo:</b> <br /></div>
                        <div>Sở Khoa Học và Công Nghệ</div>
                    </td>
                </tr>
                <tr>
                    <td className="vertical-align" align="center" rowspan="1" colspan="4"></td>
                    <td className="vertical-align" align="center" rowspan="1" colspan="3"><i>Đơn vị tính: {unit}</i></td>
                </tr>
            </tbody>
        </table>
    );

}


const mapStateToProps = state => ({
    userInfo: get(state, 'authState.userInfo.data', {}),
});

const mapDispatchToProps = {
    actionGetUserMetadata,
};


export default connect(mapStateToProps, mapDispatchToProps)(TableHeader);