import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { api } from 'app/utils';
import { get, isEmpty } from 'lodash';
import { getUserId } from 'app/utils';
import { actionLogOut, actionGetUserMetadata, actionGetWorkspace, actionGetAllRole, actionGetAllReports } from 'app/redux/actions';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useToken, useRouter } from 'app/hooks';
import Spinner from 'app/shared/Spinner';
import { Routers } from 'app/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { AdminSchema } from 'app/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

function ManagementUserAdd(props) {
    const { history,
        authState,
        workSpaces,
        actionGetWorkspace,
        gettingWorkspace,
        listRoles,
        gettingRoles,
        actionGetAllRole,
        listReports,
        gettingReports,
        actionGetAllReports
    } = props;

    const routers = useRouter();
    const { location } = props || {}

    const { setToken } = useToken();
    const [isLoading, setIsLoading] = useState(false);
    const [workSpaceSelected, setWorkSpaceSelected] = useState('');
    const [roleUserId, setRoleId] = useState('');

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(AdminSchema)
    });

    const [form, setForm] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        userName: '',
        roleID: '',
        workSpaceID: '',
    });

    const [idAcceptReport, setIdAcceptReport] = useState([]);

    const onAddUser = async () => {
        try {
            setIsLoading(true);
            const res = await api('admin/addUser',
                {
                    ...form,
                    workSpaceID: workSpaceSelected,
                    roleID: roleUserId,
                    idReportsCanAccept: idAcceptReport,
                    userId: 'admin',
                    securityKey: 'KHCNQB',
                    created: moment()
                },
            );
            if (res.data?.error) {
                toast.error("Đã có lỗi xảy ra", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.success("Thêm tài khoản thành công!", {
                    position: "top-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTimeout(() => {
                    routers.push(Routers.ADMIN_USERS)
                }, 500)
            }

        } catch (e) {
            throw e;
        } finally {
            setIsLoading(false);
        }

    };

    const onChangeWorkSpace = (event) => {
        setWorkSpaceSelected(event.target.value);
    }

    const onChangeRole = (event) => {
        setRoleId(event.target.value);
    }

    const onChangeTextField = (field, event) => {
        setForm({ ...form, [field]: event.target.value })
    };


    const onChangeAcceptReport = (event, id) => {
        const value = event.target.checked;
        let array = [...idAcceptReport];
        if (value === true) {
            if (idAcceptReport.indexOf(id === -1)) {
                array.push(id);
                setIdAcceptReport(array);
            }
        } else {
            if (idAcceptReport.includes(id)) {
                const idx = idAcceptReport.indexOf(id);
                if (idx !== -1) {
                    array.splice(idx, 1);
                    setIdAcceptReport(array);
                }
            }
        }
    }

    useEffect(() => {
        if (isEmpty(workSpaces)) {
            actionGetWorkspace('admin');
        }
        if (isEmpty(listRoles)) {
            actionGetAllRole('admin');
        }
        if (isEmpty(listReports)) {
            actionGetAllReports({ userId: 'admin' })
        }
    }, [])


    if (isLoading || gettingWorkspace || gettingRoles || gettingReports) {
        return <Spinner />
    }


    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Thêm tài khoản người dùng</h3>
            </div>
            <div className="row" style={{ backgroundColor: 'white' }}>
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Nhập đầy đủ thông tin</h4>
                            <form className="forms-sample mt-4" onSubmit={handleSubmit(onAddUser)}>

                                <div class="form-group"><label for="fullName">Họ và tên</label>
                                    <input placeholder="Họ và tên" type="text" id="fullName" class="form-control" aria-autocomplete="list"
                                        {...register("fullName")}
                                        onChange={event => onChangeTextField("fullName", event)}
                                    />
                                    <p class="invalid-validate">{errors.fullName?.message}</p>
                                </div>

                                <div class="form-group"><label for="userName">Tài khoản đăng nhập</label>
                                    <input placeholder="Tên đăng nhập" type="text" id="userName" class="form-control" aria-autocomplete="list"
                                        {...register("userName")}
                                        onChange={event => onChangeTextField("userName", event)}
                                    />
                                    <p class="invalid-validate">{errors.userName?.message}</p>
                                </div>

                                <div class="form-group"><label for="email">Email</label>
                                    <input placeholder="Email" type="text" id="email" class="form-control" aria-autocomplete="list"
                                        {...register("email")}
                                        onChange={event => onChangeTextField("email", event)}
                                    />
                                    <p class="invalid-validate">{errors.email?.message}</p>
                                </div>

                                <div class="form-group"><label for="phone">SDT</label>
                                    <input placeholder="SDT" type="text" id="phone" class="form-control" aria-autocomplete="list"
                                        {...register("phoneNumber")}
                                        onChange={event => onChangeTextField("phoneNumber", event)}
                                    />
                                    <p class="invalid-validate">{errors.phoneNumber?.message}</p>
                                </div>

                                <div class="form-group"> <label htmlFor="workSpaces">Cơ quan</label>
                                    <select className="form-control form-control-lg" id="workSpaces" value={workSpaceSelected}
                                        {...register("workSpace")}
                                        onChange={(event) => onChangeWorkSpace(event)}>
                                        <option value="">-Chọn nơi cơ quan </option>
                                        {workSpaces.map(item => (
                                            <option value={get(item, 'id')}>{get(item, 'name')}</option>
                                        ))}
                                    </select>
                                    <p class="invalid-validate">{errors.workSpace?.message}</p>
                                </div>

                                <div class="form-group">  <label htmlFor="roles">Phân quyền</label>
                                    <select className="form-control form-control-lg" id="workSpaces" value={roleUserId}
                                        {...register("roleId")}
                                        onChange={(event) => onChangeRole(event)}>
                                        <option value="">-Chọn một quyền</option>
                                        {listRoles.map(item => (
                                            <option value={get(item, 'id')}>{get(item, 'desc')}</option>
                                        ))}
                                    </select>
                                    <p class="invalid-validate">{errors.roleId?.message}</p>
                                </div>

                                <button type="submit" className="btn btn-primary mr-2" style={{ width: 100, height: 50 }} disabled={isLoading}>Thêm</button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card-body">
                        <h4 className="card-title">Phân quyền truy cập báo cáo</h4>
                        <p className="card-description">Click chọn các báo cáo mà tài khoản có thể nhập</p>

                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form.Group>
                                        {listReports && listReports.map(report => {
                                            const { id } = report;
                                            const checked = idAcceptReport.includes(id);
                                            return (
                                                <div className="form-check mt-4 form-check-primary">
                                                    <label className="form-check-label">
                                                        <input type="checkbox" className="form-check-input" defaultChecked={checked} value={checked} onChange={event => onChangeAcceptReport(event, id)} />
                                                        <i className="input-helper"></i>
                                                        {get(report, 'title')}
                                                    </label>
                                                </div>
                                            )
                                        })}

                                    </Form.Group>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    authState: get(state, 'authState'),
    userInfo: get(state, 'authState.userInfo.data', {}),
    workSpaces: get(state, 'authState.workSpaces.data', []),
    gettingWorkspace: get(state, 'authState.gettingWorkspace'),
    listRoles: get(state, 'authState.roles.data', []),
    gettingRoles: get(state, 'authState.gettingRoles'),
    listReports: get(state, 'authState.listReports.data', []),
    gettingReports: get(state, 'authState.gettingReports'),
});

const mapDispatchToProps = {
    actionLogOut,
    actionGetUserMetadata,
    actionGetWorkspace,
    actionGetAllRole,
    actionGetAllReports
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementUserAdd))
