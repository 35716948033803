import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { actionGetUserMetadata, actionGetAnalysis, actionGetWorkspace, actionGetAllRole, actionGetAllReports } from 'app/redux/actions';
import { withRouter, Link } from 'react-router-dom';
import Spinner from 'app/shared/Spinner';
import { ProgressBar } from 'react-bootstrap';
import { api } from 'app/utils';
import { DATA_REPORT_LIST, Routers } from 'app/constants'
import { KEY_SECURITY } from 'app/configs';
import moment from 'moment';
const iconChecked = require('../../assets/images/icon/checked.png');
const iconWarning = require('../../assets/images/icon/warning.png');

function AdminDashboard(props) {

  const { authState, history, reportsState } = props || {};
  const { isGetttingAnalysis } = reportsState || false;

  const [dataTable, setDataTable] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [yearSelected, setYearSelected] = useState(moment().year());


  const fetchData = async () => {
    try {
      setIsloading(true);
      const requestData = {
        userId: 'admin',
        securityKey: KEY_SECURITY,
        year: Number(yearSelected)
      };

      const resp = await api(`admin/processAllUnit`, requestData);
      const users = get(resp, 'data.data', []);
      if (users) {
        setDataTable(users)
      }
    } catch (err) {
    } finally {
      setIsloading(false);
    }
  };


  const onChangeYear = (e) => {
    setYearSelected(e.target.value);
  };



  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    fetchData();
  }, [yearSelected]);



  if (isGetttingAnalysis || isLoading) {
    return <Spinner style={{ position: null }} />;
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          Tiến độ báo cáo theo tài khoản
        </h3>
      </div>
      <div className="row">



        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              {/* <h4 className="card-title">Tiến độ báo cáo theo đơn vị</h4> */}
              <div class="form-group d-flex p-2 align-center text-center" style={{ alignItems: 'center', justifyContent: 'center' }}>
                <label className="mr-2 align-center" for="controlYear">Chọn năm báo cáo</label>
                <select class="form-control" id="controlYear" style={{ width: '120px' }}
                  value={yearSelected} onChange={(event) => onChangeYear(event)}>
                  <option value={2018}>2018</option>
                  <option value={2019}>2019</option>
                  <option value={2020}>2020</option>
                  <option value={2021}>2021</option>
                  <option value={2022}>2022</option>
                  <option value={2023}>2023</option>
                  <option value={2024}>2024</option>
                </select>
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> STT </th>
                      <th>Trạng thái</th>
                      {/* <th>%</th> */}
                      <th>Tên Tài khoản</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTable && dataTable.map((item, idx) => {
                      return (
                        <tr>
                          <td>{idx + 1}</td>
                          <td>
                            <img src={get(item, 'ratioSum', 0) === 0 ? iconWarning : iconChecked} style={{ width: 24, height: 24 }} />
                          </td>
                          {/* <td>
                            <b className="text-primary"> {(get(item, 'ratioSum', 0)).toFixed(2)}</b>
                          </td> */}
                          <td><Link className="nav-link"
                            to={{
                              pathname: Routers.ADMIN_REPORT_VIEW,
                              state: { followUserId: get(item, 'userId'), year: yearSelected, idReportsCanAccept: get(item, 'metadata.idReportsCanAccept', []) }
                            }}>{get(item, 'metadata.fullName')} </Link></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportsState: get(state, 'reportState'),
});

const mapDispatchToProps = {
  actionGetUserMetadata,
  actionGetAnalysis,
  actionGetWorkspace,
  actionGetAllReports
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDashboard))
