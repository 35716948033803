import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { useToken } from 'app/hooks';
import { get } from 'lodash';
import { actionLogin, actionGetUserMetadata } from 'app/redux/actions';
import { connect } from 'react-redux';
import Spinner from 'app/shared/Spinner';
import { LoginSchema } from 'app/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { withRouter } from 'react-router-dom';

function Login(props) {
  const { actionLogin, authState, actionGetUserMetadata, history } = props;

  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { setToken } = useToken();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(LoginSchema)
  });

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await actionLogin({
        userName,
        password
      });
      const { data } = response || {};
      const { token, userId, roleID } = get(data, 'data') || {}
      if (token) {
        await setToken({ token });
        actionGetUserMetadata(userId);
        localStorage.setItem('userId', JSON.stringify({ userId }));
        localStorage.setItem('roleID', JSON.stringify({ roleID }));
        if (roleID === 'admin') {
          history.replace('/dashboard');
        }
        else {
          history.replace('/report');
        }

      }

    } catch (e) {
      throw e;
    } finally {
      setIsLoading(false);
    }
  }
  if (isLoading) {
    return (
      <Spinner />
    )
  }


  return (
    <div className="d-flex align-items-center auth px-0" style={
      {
        backgroundImage: 'url(' + require('../../assets/images/auth/bg-login-new.jpeg') + ')',
        height: "100%",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }
    }>
      <div className="row w-100 mx-0">
        <div className="col-lg-4 mx-auto">
          <div className="auth-form-light text-left py-5 px-4 px-sm-5" style={{opacity: 0.93}}>
            <div className="brand-logo">
              <img src={require("../../assets/images/logo.svg")} alt="logo" />
            </div>
            <h4 className="text-center">SỞ KHOA HỌC VÀ CÔNG NGHỆ<br /> TỈNH QUẢNG BÌNH</h4>
            <br />
            <h6 className="font-weight-light text-center">BÁO CÁO THỐNG KÊ KHOA HỌC VÀ CÔNG NGHỆ TRỰC TUYẾN</h6>
            <form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
              <div class="form-group"><label for="exampleInputPassword1">Tài khoản</label>
                <input placeholder="Tài khoản đăng nhập" type="text" id="userName" class="form-control" aria-autocomplete="list"
                  {...register("userName")}
                  onChange={e => setUserName(e.target.value)}
                />
                <p class="invalid-validate">{errors.userName?.message}</p>
              </div>
              <div class="form-group"><label for="exampleInputPassword1">Mật khẩu</label>
                <input placeholder="Mật khẩu" type="password" id="password" class="form-control" aria-autocomplete="list"
                  {...register("password")}
                  onChange={e => setPassword(e.target.value)} />
                <p class="invalid-validate">{errors.password?.message}</p>
              </div>
              <div className="mt-3">
                <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="submit"  >Đăng Nhập</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  authState: get(state, 'authState'),
});

const mapDispatchToProps = {
  actionLogin,
  actionGetUserMetadata
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
Login.propTypes = {
  setToken: PropTypes.func.isRequired
};



