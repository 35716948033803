import * as yup from "yup";

export const AdminSchema = yup.object({
    userName: yup.string().required('Nhập đầy đủ thông tin'),
    fullName: yup.string().required('Nhập đầy đủ thông tin'),
    email: yup.string().required('Nhập đầy đủ thông tin'),
    phoneNumber: yup.string().required('Nhập đầy đủ thông tin'),
    workSpace: yup.string().required('Chọn cơ quan'),
    roleId: yup.string().required('Chọn quyền'),
}).required();

export const AdminWorlspaceSchema = yup.object({
    name: yup.string().required('Nhập đầy đủ thông tin'),
}).required();

export const AdminSendMessage = yup.object({
    title: yup.string().required('Nhập tiêu đề'),
    content: yup.string().required('Nhập nội dung'),
}).required();