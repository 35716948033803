import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import {
  CommonTableHeaderReport,
  CommonTableFooterReport, HeaderExport,
  FooterUpload, inputStyle
} from 'app/components';
import { get } from 'lodash';
import { getUserId } from 'app/utils';
import { connect } from 'react-redux';
import { actionGetStorageReportDetail } from 'app/redux/actions';
import { api } from 'app/utils';
import Spinner from 'app/shared/Spinner';
import parse from 'html-react-parser';
import * as FileSaver from 'file-saver';
import { useRouter } from 'app/hooks';
import { Routers } from 'app/constants'

function ReportDetail(props) {

  const { location, actionViewtemplate, reportState, tableData,
    idReportUser, isGetttingReportDetail, reportDetailTitle,
    reportDetailHeader, reportDetailFooter } = props;

  const { idReport, year, followUserId } = get(location, 'state');
  const router = useRouter();

  const userId = followUserId ? followUserId : getUserId();

  if (!idReport) {
    router.push(Routers.REPORT);
  }

  const isLoadingUploadFile = get(reportState, 'isLoadingUploadFile');

  const [dynamicTableData, setDynamicTableData] = useState(tableData);
  const [idxRowsDisabled, setidxRowsDisabled] = useState([0]);
  const [exportting, setExportting] = useState(false);
  const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();

  const onSubmit = async () => {
    const reqData = { userId, idReportUser, rawData: dynamicTableData };
    api('reports/updateDataSaved', reqData);
  }

  const onChangeTimeFooter = ({ dateValue, monthValue }) => {
    if (dateValue && monthValue)
      api('reports/updateDateMonthSaved', { userId, idReportUser, date: dateValue, month: monthValue });
  }

  const inputChange = async ({ event, id, idxRow, idxCol }) => {
    const valueText = event.target.value ? parseInt(event.target.value) : 0;
    let change = [...dynamicTableData];
    setValue(id, valueText);
    change[idxRow].rowCols[idxCol].value = valueText;
    if (idxRow === 1 || idxRow === 2) {
      const sumByCol2 = parseInt(change[1].rowCols[3].value) + parseInt(change[2].rowCols[3].value);
      setValue(change[0].rowCols[3].id, sumByCol2);
      change[0].rowCols[3].value = sumByCol2;

      const sumByCol3 = parseInt(change[1].rowCols[4].value) + parseInt(change[2].rowCols[4].value);
      setValue(change[0].rowCols[4].id, sumByCol3);
      change[0].rowCols[4].value = sumByCol3;

      const sumByCol4 = parseInt(change[1].rowCols[5].value) + parseInt(change[2].rowCols[5].value);
      setValue(change[0].rowCols[5].id, sumByCol4);
      change[0].rowCols[5].value = sumByCol4;

      const sumByCol5 = parseInt(change[1].rowCols[6].value) + parseInt(change[2].rowCols[6].value);
      setValue(change[0].rowCols[6].id, sumByCol5);
      change[0].rowCols[6].value = sumByCol5;

      const sumByCol6 = parseInt(change[1].rowCols[7].value) + parseInt(change[2].rowCols[7].value);
      setValue(change[0].rowCols[7].id, sumByCol6);
      change[0].rowCols[7].value = sumByCol6;
    }

    const sumCol34 = parseInt(change[idxRow].rowCols[4].value) + parseInt(change[idxRow].rowCols[5].value);
    setValue(change[idxRow].rowCols[2].id, sumCol34);
    change[idxRow].rowCols[2].value = sumCol34;

    const sumCol34Row0 = parseInt(change[0].rowCols[4].value) + parseInt(change[0].rowCols[5].value);
    setValue(change[0].rowCols[2].id, sumCol34Row0);
    change[0].rowCols[2].value = sumCol34Row0;
    //save to DB
    setDynamicTableData(change);
    const reqData = { valueText, userId, idReportUser, rawData: change };
    api('reports/updateDataSaved', reqData);
  }

  const onExportExcel = async () => {
    try {
      setExportting(true);
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const fileName = 'Bieu04_KHCN_NV';
      const response = await api('reports/exportExcel', {
        dataList: dynamicTableData,
        idReport: idReport
      });
      const buffer = get(response, 'data.buffer.data');
      const arr = new Uint8Array(buffer);
      const dataFile = new Blob([arr], { type: fileType });
      FileSaver.saveAs(dataFile, fileName + fileExtension);
    } catch (e) {
      console.log('onExportExcel error', e)
    }
    finally {
      setExportting(false);
    }
  }

  const analysisReport = async () => {
    try {
      setExportting(true);
      let sumInput = 0, sumTyped = 0;
      let ratio = 0;
      if (!dynamicTableData) return;
      dynamicTableData.map(row => {
        row.rowCols.map(col => {
          const { type, value } = col;
          if (type === 'input' && value > 0) {
            sumTyped++;
          }
          if (type === 'input') {
            sumInput++;
          }
        })
      });
      if (sumTyped !== 0){
        ratio = Number(sumTyped / sumInput);
      }
      const data = await api('reports/analysisByUser', {
        userId: userId,
        idReport: idReport,
        ratio: ratio,
        year: year
      });
      return data;
    } catch (e) {

    } finally {
      setExportting(false);
    }
  }

  const onFinishReport = async () => {
    await analysisReport();
    router.push("/report")
  }

  useEffect(() => {
    if (tableData) {
      setDynamicTableData(tableData);
    }
  }, [tableData]);


  useEffect(() => {
    actionViewtemplate({ idReport, year, userId });
    //analysisReport();
  }, []);


  if (isGetttingReportDetail || exportting || isLoadingUploadFile) {
    return <Spinner />
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> {reportDetailTitle}</h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <HeaderExport onExportExcel={(() => onExportExcel())} />
              <div className="table-responsive">
                <CommonTableHeaderReport data={reportDetailHeader} title={reportDetailTitle} userId={userId}/>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="3" colspan="1" width="20%"></td>
                        <td className="vertical-align" align="center" rowspan="3" colspan="1">Mã số</td>
                        <td className="vertical-align" align="center" rowspan="3" colspan="1" width="10%">Tổng số</td>
                        <td className="vertical-align" align="center" rowspan="3" colspan="1">Trong đó: Số nhiệm vụ KH&CN chủ nhiệm là nữ</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="4">Tình trạng tiến hành</td>
                      </tr>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="2">Số đang tiến hành</td>
                        <td className="vertical-align" align="center" rowspan="2" colspan="1">Số được nghiệm thu</td>
                        <td className="vertical-align" align="center" rowspan="2" colspan="1">Số đã đưa vào ứng dụng</td>
                      </tr>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">Số phê duyệt mới trong năm</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">Số chuyển tiếp từ năm trước</td>
                      </tr>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">A	</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">B</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">1</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">2</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">3</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">4</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">5</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">6</td>
                      </tr>

                      {tableData && tableData.map((row, idxRow) => {
                        return (
                          <tr>
                            {get(row, 'rowCols', []).map((col, idxCol) => {
                              const { id, type } = col || {};
                              if (type === 'title') return (<td className="vertical-align" rowspan="1" colspan="1" >{parse(get(col, 'title'))}</td>);
                              if (type === 'code') return (<td className="vertical-align" rowspan="1" align="center" colspan="1">{get(col, 'title')}</td>);
                              if (type === 'input') {
                                return (
                                  <td className="vertical-align" align="center" rowspan="1" colspan="1">
                                    <input type="number"
                                      readOnly={idxRowsDisabled.includes(idxRow) || idxCol === 2 || userId === 'admin' || followUserId}
                                      {...register(get(col, 'id'))}
                                      name={get(col, 'id')}
                                      min={0}
                                      id={get(col, 'id')}
                                      class="form-control-sm form-control form-input"
                                      style={inputStyle}
                                      defaultValue={get(col, 'value', 0)}
                                      onChange={event => inputChange({ event, id, idxRow, idxCol })}
                                    />
                                  </td>
                                )
                              }
                              return (<td></td>);

                            })}
                          </tr>

                        )
                      })}

                    </tbody>
                  </table>
                </form>
                <CommonTableFooterReport data={reportDetailFooter} onChangeTime={onChangeTimeFooter} />
                <FooterUpload onFinishReport={onFinishReport} storageID={idReportUser}
                  fileUploaded={get(reportState, 'reportDetail.data.fileUpload')}
                  onFinishUpload={() => actionViewtemplate({ idReport, year, userId })}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportState: get(state, 'reportState'),
  tableData: get(state, 'reportState.reportDetail.data.rawData', []),
  sizeCols: get(state, 'reportState.reportDetail.data.size', 1),
  idReportUser: get(state, 'reportState.reportDetail.data.id'),
  isGetttingReportDetail: get(state, 'reportState.isGetttingReportDetail'),
  reportDetailHeader: get(state, 'reportState.reportDetail.data.headers', {}),
  reportDetailTitle: get(state, 'reportState.reportDetail.data.title', ''),
  reportDetailFooter: get(state, 'reportState.reportDetail.data.footer', {}),

});

const mapDispatchToProps = {
  actionViewtemplate: actionGetStorageReportDetail
};


export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail)
