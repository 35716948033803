import React, { Component } from 'react';
// import { Trans } from 'react-i18next';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © <a href="#" target="_blank" rel="noopener noreferrer">Bản quyền thuộc về Sở Khoa học và Công nghệ tỉnh Quảng Bình. Cơ quan chủ quản: UBND tỉnh Quảng Bình</a> 2021</span>
            {/* <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Free <a href="https://www.bootstrapdash.com/react-admin-templates/" target="_blank" rel="noopener noreferrer"> react admin </a> templates from BootstrapDash.com.  </span> */}
          </div>
          <p>Địa chỉ: 17A Quang Trung - TP.Đồng Hới - Quảng Bình</p>
          <p>Điện thoại: 0232.3828129, FAX: 0232.3822808</p>
          <p>Email: skhcn@quangbinh.gov.vn</p>
          {/* <p>Giấy phép thiết lập trang thông tin điện tử tổng hợp trên mạng số: 04/GP-STTTT cấp ngày 03/4/2015 của Sở Thông tin và Truyền thông Quảng Bình</p> */}
        </div>
      </footer>
    );
  }
}

export default Footer;