import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import {
  CommonTableHeaderReport,
  CommonTableFooterReport, HeaderExport,
  FooterUpload, inputStyle,
  SelectCustom, InputCustom
} from 'app/components';
import { get, isEmpty } from 'lodash';
import { getUserId } from 'app/utils';
import { connect } from 'react-redux';
import { actionGetStorageReportDetail } from 'app/redux/actions';
import { api } from 'app/utils';
import Spinner from 'app/shared/Spinner';
import parse from 'html-react-parser';
import shortid from 'shortid';
import { DATA_ECOMIC_MAJOR, TYPES_INPUT, DATA_COUNTIRES } from 'app/constants';
import * as FileSaver from 'file-saver';
import { useRouter } from 'app/hooks';
import { Routers } from 'app/constants'

function ReportDetail(props) {

  const { location, actionViewtemplate, reportState, tableData = [],
    idReportUser, isGetttingReportDetail, reportDetailTitle,
    reportDetailHeader, reportDetailFooter, idCanNotRemove, idDisabledInput } = props;
  const router = useRouter();

  const { idReport, year, followUserId } = get(location, 'state');
  const userId = followUserId ? followUserId : getUserId();

  if (!idReport) {
    router.push(Routers.REPORT);
  }

  const isLoadingUploadFile = get(reportState, 'isLoadingUploadFile');

  const [dynamicTableData, setDynamicTableData] = useState([]);

  const [listidCanNotRemove, setListidCanNotRemove] = useState([]);
  const [exportting, setExportting] = useState(false);

  const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();

  const onSubmit = async () => {
    const reqData = { userId, idReportUser, rawData: dynamicTableData };
    api('reports/updateDataSaved', reqData);
  }

  const onChangeTimeFooter = ({ dateValue, monthValue }) => {
    if (dateValue && monthValue)
      api('reports/updateDateMonthSaved', { userId, idReportUser, date: dateValue, month: monthValue });
  }

  const inputChange = async ({ event, id, idxRow, idxCol }) => {
    const valueText = event.target.value ? parseInt(event.target.value) : 0;
    let change = [...dynamicTableData];
    setValue(id, valueText);
    change[idxRow].rowCols[idxCol].value = valueText;

    const code = change[idxRow].rowCols[1].title;
    if (code === '02' || code === '03') {
      let sumColTongSo = 0;
      let sumColTrongKiBaoCao = 0;
      change.map(row => {
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '02'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '03'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
      });

      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.type === 'code' && col.title === '01'));
      if (idxRowSum === -1) return;
      setValue(change[idxRowSum].rowCols[3].id, sumColTongSo);
      change[idxRowSum].rowCols[3].value = sumColTongSo;
      setValue(change[idxRowSum].rowCols[4].id, sumColTrongKiBaoCao);
      change[idxRowSum].rowCols[4].value = sumColTrongKiBaoCao;
    }

    if (code === '14') {
      let setSumByCode1 = 0;
      let setSumByCode2 = 0;
      change.map(row => {
        if (row.rowCols.find(col => col.type === 'code' && col.title === code)) {
          if (!idDisabledInput.includes(row.rowCols[3].id)) {
            setSumByCode1 += parseInt(row.rowCols[3].value);
            setSumByCode2 += parseInt(row.rowCols[4].value)
          }
        }
      });
      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.title === code));
      if (!idxRowSum) return;
      setValue(change[idxRowSum].rowCols[3].id, setSumByCode1);
      change[idxRowSum].rowCols[3].value = setSumByCode1;
      setValue(change[idxRowSum].rowCols[4].id, setSumByCode2);
      change[idxRowSum].rowCols[4].value = setSumByCode2;
    }

    if (code === '38' || code === '39') {
      let sumColTongSo = 0;
      let sumColTrongKiBaoCao = 0;
      change.map(row => {
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '38'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '39'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
      });

      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.type === 'code' && col.title === '37'));
      if (idxRowSum === -1) return;
      setValue(change[idxRowSum].rowCols[3].id, sumColTongSo);
      change[idxRowSum].rowCols[3].value = sumColTongSo;
      setValue(change[idxRowSum].rowCols[4].id, sumColTrongKiBaoCao);
      change[idxRowSum].rowCols[4].value = sumColTrongKiBaoCao;
    }

    if (code === '42' || code === '43') {
      let sumColTongSo = 0;
      let sumColTrongKiBaoCao = 0;
      change.map(row => {
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '42'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '43'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
      });

      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.type === 'code' && col.title === '41'));
      if (idxRowSum === -1) return;
      setValue(change[idxRowSum].rowCols[3].id, sumColTongSo);
      change[idxRowSum].rowCols[3].value = sumColTongSo;
      setValue(change[idxRowSum].rowCols[4].id, sumColTrongKiBaoCao);
      change[idxRowSum].rowCols[4].value = sumColTrongKiBaoCao;
    }

    if (code === '23' || code === '24' || code === '25' || code === '26' || code === '27' || code === '28') {
      let sumColTongSo = 0;
      let sumColTrongKiBaoCao = 0;
      change.map(row => {
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '23'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '24'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '25'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '26'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '27'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '28'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
      });

      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.type === 'code' && col.title === '23'));
      if (idxRowSum === -1) return;
      setValue(change[idxRowSum - 1].rowCols[3].id, sumColTongSo);
      change[idxRowSum - 1].rowCols[3].value = sumColTongSo;
      setValue(change[idxRowSum - 1].rowCols[4].id, sumColTrongKiBaoCao);
      change[idxRowSum - 1].rowCols[4].value = sumColTrongKiBaoCao;
    }

    if (code === '31' || code === '32' || code === '33' || code === '34' || code === '35' || code === '36') {
      let sumColTongSo = 0;
      let sumColTrongKiBaoCao = 0;
      change.map(row => {
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '31'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '32'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '33'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '34'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '35'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '36'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
      });

      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.type === 'code' && col.title === '30'));
      if (idxRowSum === -1) return;
      setValue(change[idxRowSum].rowCols[3].id, sumColTongSo);
      change[idxRowSum].rowCols[3].value = sumColTongSo;
      setValue(change[idxRowSum].rowCols[4].id, sumColTrongKiBaoCao);
      change[idxRowSum].rowCols[4].value = sumColTrongKiBaoCao;
    }

    if (code === '45' || code === '46' || code === '47' || code === '48' || code === '49' || code === '50') {
      let sumColTongSo = 0;
      let sumColTrongKiBaoCao = 0;
      change.map(row => {
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '45'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '46'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '47'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '48'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '49'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '50'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
      });

      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.type === 'code' && col.title === '44'));
      if (idxRowSum === -1) return;
      setValue(change[idxRowSum].rowCols[3].id, sumColTongSo);
      change[idxRowSum].rowCols[3].value = sumColTongSo;
      setValue(change[idxRowSum].rowCols[4].id, sumColTrongKiBaoCao);
      change[idxRowSum].rowCols[4].value = sumColTrongKiBaoCao;
    }

    if (code === '16' || code === '17' || code === '18' || code === '19' || code === '20') {
      let sumColTongSo = 0;
      let sumColTrongKiBaoCao = 0;
      change.map(row => {
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '16'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '17'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '18'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '19'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
        if (row.rowCols.find(col => (col.type === 'code' && col.title === '20'))) {
          sumColTongSo += parseInt(row.rowCols[3].value);
          sumColTrongKiBaoCao += parseInt(row.rowCols[4].value)
        }
      });

      const idxRowSum = change.findIndex(row => row.rowCols.find(col => col.type === 'code' && col.title === '15'));
      if (idxRowSum === -1) return;
      setValue(change[idxRowSum].rowCols[3].id, sumColTongSo);
      change[idxRowSum].rowCols[3].value = sumColTongSo;
      setValue(change[idxRowSum].rowCols[4].id, sumColTrongKiBaoCao);
      change[idxRowSum].rowCols[4].value = sumColTrongKiBaoCao;
    }


    //save to DB
    setDynamicTableData(change);
    const reqData = { valueText, userId, idReportUser, rawData: change };
    api('reports/updateDataSaved', reqData);
  };

  const onAddRow = ({ idxRow, idxCol }) => {
    let newChanged = [...dynamicTableData];
    let rowAddData = { ...newChanged[idxRow] };
    rowAddData.rowCols = rowAddData.rowCols.map(item => {
      return { ...item, id: shortid.generate() }
    })
    newChanged.splice(idxRow + 1, 0, rowAddData);
    setDynamicTableData(newChanged);
    api('reports/updateDataSaved', { userId, idReportUser, rawData: newChanged });
  }

  const onDeleteRow = ({ idxRow, idxCol }) => {
    let newChanged = [...dynamicTableData];
    newChanged.splice(idxRow, 1);
    setDynamicTableData(newChanged);
    api('reports/updateDataSaved', { userId, idReportUser, rawData: newChanged });
  }

  const onRowChangeSelect = ({ idxRow, idxCol, newVal, type }) => {
    let newChanged = [...dynamicTableData];
    newChanged[idxRow].rowCols[idxCol].value = newVal;
    if (type == TYPES_INPUT.SELECT_COUNTRY) {
      const find = DATA_COUNTIRES.find(country => country.id == newVal) || DATA_COUNTIRES[0];
      newChanged[idxRow].rowCols[idxCol].title = get(find, 'name');
    }
    if (type == TYPES_INPUT.SELECT_MAJOR_EMOMIC) {
      const find = DATA_ECOMIC_MAJOR.find(country => country.id == newVal) || DATA_ECOMIC_MAJOR[0];
      newChanged[idxRow].rowCols[idxCol].title = get(find, 'name');
    }
    setDynamicTableData(newChanged);
    const reqData = { userId, idReportUser, rawData: newChanged };
    api('reports/updateDataSaved', reqData);
  }

  const onExportExcel = async () => {
    try {
      setExportting(true);
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const fileName = 'Bieu09_KHCN_TĐC';
      const response = await api('reports/exportExcel', {
        dataList: dynamicTableData,
        idReport: idReport
      });
      const buffer = get(response, 'data.buffer.data');
      const arr = new Uint8Array(buffer);
      const dataFile = new Blob([arr], { type: fileType });
      FileSaver.saveAs(dataFile, fileName + fileExtension);
    } catch (e) {
      console.log('onExportExcel error', e)
    }
    finally {
      setExportting(false);
    }
  }

  const analysisReport = async () => {
    try {
      setExportting(true);
      let sumInput = 0, sumTyped = 0;
      let ratio = 0;
      if (!dynamicTableData) return;
      dynamicTableData.map(row => {
        row.rowCols.map(col => {
          const { type, value } = col;
          if (type === 'input' && value > 0) {
            sumTyped++;
          }
          if (type === 'input') {
            sumInput++;
          }
        })
      });
      if (sumTyped !== 0){
        ratio = Number(sumTyped / sumInput);
      }
      const data = await api('reports/analysisByUser', {
        userId: userId,
        idReport: idReport,
        ratio: ratio,
        year: year
      });
      return data;
    } catch (e) {

    } finally {
      setExportting(false);
    }
  }

  const onFinishReport = async () => {
    await analysisReport();
    router.push("/report")
  }

  useEffect(() => {
    if (tableData) {
      setDynamicTableData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (idCanNotRemove) {
      setListidCanNotRemove(idCanNotRemove);
    }
  }, [idCanNotRemove]);

  useEffect(() => {
    actionViewtemplate({ idReport, year, userId });
   // analysisReport();
  }, []);


  if (isGetttingReportDetail || exportting || isLoadingUploadFile) {
    return <Spinner />
  }


  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> {reportDetailTitle}</h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <HeaderExport onExportExcel={() => onExportExcel()} />
              <div className="table-responsive">
                <CommonTableHeaderReport data={reportDetailHeader} title={reportDetailTitle} userId={userId} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1" width="40%"></td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">Mã số</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1" width="15%">Đơn vị tính</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">Tổng số</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">Trong kỳ báo cáo</td>
                      </tr>

                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">A	</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">B</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">C</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">1</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">2</td>
                      </tr>

                      {dynamicTableData && dynamicTableData.map((row, idxRow) => {
                        return (
                          <tr key={idxRow}>
                            {get(row, 'rowCols', []).map((col, idxCol) => {
                              const { id, type } = col || {};
                              if (type === 'title') return (<td className="vertical-align" rowspan="1" colspan="1" >{parse(get(col, 'title'))}</td>);
                              if (type === 'code') return (<td className="vertical-align" rowspan="1" align="center" colspan="1"> {parse(get(col, 'title', ''))}</td>);
                              if (type === 'input') {
                                return (
                                  <td className="vertical-align" align="center" rowspan="1" colspan="1">
                                    <input type="number"
                                      readOnly={idDisabledInput.includes(id) || userId === 'admin' || followUserId}
                                      {...register(get(col, 'id'))}
                                      name={get(col, 'id')}
                                      min={0}
                                      id={get(col, 'id')}
                                      class="form-control-sm form-control form-input"
                                      style={inputStyle}
                                      defaultValue={get(col, 'value', 0)}
                                      onChange={event => inputChange({ event, id, idxRow, idxCol })}
                                    />
                                  </td>
                                )
                              }
                              if (type === TYPES_INPUT.SELECT_MAJOR_EMOMIC) {
                                return (
                                  <SelectCustom
                                    options={DATA_ECOMIC_MAJOR}
                                    onAdd={() => onAddRow({ idxRow, idxCol })}
                                    onDelelele={() => onDeleteRow({ idxRow, idxCol })}
                                    onChangeSelect={(newVal) => onRowChangeSelect({ idxRow, idxCol, newVal })}
                                    defaultValue={get(col, 'value', '')}
                                    canNotDeleteRow={listidCanNotRemove.includes(id)}
                                  />

                                )
                              }
                              if (type === TYPES_INPUT.SELECT_COUNTRY) {
                                return (
                                  <SelectCustom
                                    options={DATA_COUNTIRES}
                                    onAdd={() => onAddRow({ idxRow, idxCol })}
                                    onDelelele={() => onDeleteRow({ idxRow, idxCol })}
                                    onChangeSelect={(newVal) => onRowChangeSelect({ idxRow, idxCol, newVal })}
                                    defaultValue={get(col, 'value')}
                                    canNotDeleteRow={listidCanNotRemove.includes(id)}
                                  />

                                )
                              }
                              if (type === TYPES_INPUT.INPUT_TEXT) {
                                return (
                                  <InputCustom
                                    onAdd={() => onAddRow({ idxRow, idxCol })}
                                    onDelelele={() => onDeleteRow({ idxRow, idxCol })}
                                    onChangeText={(newVal) => onRowChangeSelect({ idxRow, idxCol, newVal })}
                                    defaultValue={get(col, 'value')}
                                    canNotDeleteRow={listidCanNotRemove.includes(id)}
                                  />

                                )
                              }
                              return (<td></td>);

                            })}
                          </tr>

                        )
                      })}
                    </tbody>
                  </table>
                </form>
                <CommonTableFooterReport data={reportDetailFooter} onChangeTime={onChangeTimeFooter} />
                <FooterUpload onFinishReport={onFinishReport} storageID={idReportUser}
                  fileUploaded={get(reportState, 'reportDetail.data.fileUpload')}
                  onFinishUpload={() => actionViewtemplate({ idReport, year, userId })}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportState: get(state, 'reportState'),
  tableData: get(state, 'reportState.reportDetail.data.rawData', []),
  sizeCols: get(state, 'reportState.reportDetail.data.size', 1),
  idCanNotRemove: get(state, 'reportState.reportDetail.data.idCanNotRemove', []),
  idDisabledInput: get(state, 'reportState.reportDetail.data.idDisabledInput', []),
  idReportUser: get(state, 'reportState.reportDetail.data.id'),
  isGetttingReportDetail: get(state, 'reportState.isGetttingReportDetail'),
  reportDetailHeader: get(state, 'reportState.reportDetail.data.headers', {}),
  reportDetailTitle: get(state, 'reportState.reportDetail.data.title', ''),
  reportDetailFooter: get(state, 'reportState.reportDetail.data.footer', {}),

});

const mapDispatchToProps = {
  actionViewtemplate: actionGetStorageReportDetail
};


export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail)
