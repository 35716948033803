export const Routers = {
    ADMIN_USERS: "/admin/user",
    ADMIN_USER_DETAIL: "/admin/user/detail",
    ADMIN_USER_ADD: "/admin/user/add",
    ADMIN_WORK_SPACE: "/admin/workspace",
    ADMIN_WORK_SPACE_DETAIL: "/admin/workspace/detail",
    ADMIN_DASH_BOARD_BY_REPORT: "/admin/byreports",
    REPORT: '/report',
    FOLLOW_BY_UNIT: '/admin/follow',
    ADMIN_REPORT_VIEW: '/admin/report/follow',
    ADMIN_SEND_MESSAGE: '/admin/send',
    NOTIFICATION: '/notify',
    NOTIFICATION_DETAIL: '/notify/detail',
    GUIDE:'/guide'
}
