import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input';
import { api } from 'app/utils';
import { get, isEmpty } from 'lodash';
import { getUserId } from 'app/utils';
import { actionLogOut, actionGetUserMetadata, actionGetWorkspace } from 'app/redux/actions';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useToken } from 'app/hooks';
import Spinner from 'app/shared/Spinner';

function ProfileUser(props) {

    const { actionLogOut, history, authState, userInfo, actionGetUserMetadata, workSpaces, actionGetWorkspace } = props;
    const { setToken } = useToken();
    const userId = getUserId();
    const [isLoading, setIsLoading] = useState(false);
    const [workSpaceName, setWorkSpaceName] = useState('');

    const [form, setForm] = useState({
        fullName: '',
        email: '',
        phoneNumber: ''
    });

    const onLogOut = () => {
        actionLogOut();
        setToken({});
        history.replace('/login')
    };

    const onUpdate = async () => {
        try {
            setIsLoading(true);
            const res = await api('auth/updateUserInfo', { ...form, userId });
            actionGetUserMetadata(userId);
        } catch (e) {
            throw e;
        } finally {
            setIsLoading(false);
        }

    };

    const onChangeName = (event) => {
        setForm({ ...form, fullName: event.target.value })
    };

    const onChangeEmail = (event) => {
        setForm({ ...form, email: event.target.value })
    };

    const onChangePhone = (event) => {
        setForm({ ...form, phoneNumber: event.target.value })
    };

    useEffect(() => {
        if (userInfo) {
            setForm({
                fullName: get(userInfo, 'fullName', ''),
                email: get(userInfo, 'email', ''),
                phoneNumber: get(userInfo, 'phoneNumber', ''),
            })
        }
    }, [userInfo]);

    useEffect(() => {
        if (workSpaces) {
            const workspaceFind = workSpaces.find(wp => wp.id === get(userInfo, 'workSpaceID', ''));
            if (!isEmpty(workspaceFind))
                setWorkSpaceName(get(workspaceFind, 'name'));
        }
    }, [workSpaces]);

    useEffect(() => {
        actionGetUserMetadata(userId);
        if (isEmpty(workSpaces)) {
            actionGetWorkspace('admin');
        }
    }, []);


    if (isLoading) {
        return <Spinner />
    }


    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Thông tin cá nhân </h3>
            </div>
            <div className="row">
                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body align-center justify-center">

                            <img className="img-ls rounded-circle" src={require("../../assets/images/faces/facePlaceHolder.png")} alt="Profile" />

                            <div className="mt-4">
                                <h6 className="left mb-4">Họ tên: <b>{get(userInfo, 'fullName', '')}</b></h6>
                                <h6 className="left mb-4">Đơn vị : <b>{workSpaceName}</b></h6>
                                <h6 className="left mb-4">Tài khoản: <b>{get(userInfo, 'userName', '')}</b></h6>
                                <h6 className="left mb-4">Email: <b>{get(userInfo, 'email', '')}</b></h6>
                                <h6 className="left mb-4">SĐT:  <b>{get(userInfo, 'phoneNumber', '')}</b></h6>
                            </div>

                            <button type="submit" className="btn btn btn-danger mt-4" style={{ width: 100, height: 50 }} onClick={() => onLogOut()}>Đăng xuất</button>

                        </div>
                    </div>
                </div>

                <div className="col-md-6 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="card-title">Chỉnh sửa thông tin</h3>
                            <form className="forms-sample mt-4">
                                <Form.Group>
                                    <label htmlFor="exampleInputUsername1">Tên</label>
                                    <Form.Control type="text" id="exampleInputUsername1" placeholder="Ten" size="lg" value={form.fullName} onChange={(event) => onChangeName(event)} />
                                </Form.Group>
                                <Form.Group>
                                    <label htmlFor="exampleInputEmail1">Email</label>
                                    <Form.Control type="email" className="form-control" id="email" placeholder="Email" value={form.email} onChange={(event) => onChangeEmail(event)} />
                                </Form.Group>

                                <Form.Group>
                                    <label htmlFor="exampleInputEmail1">SĐT</label>
                                    <Form.Control type="phone" className="form-control" id="exampleInputEmail1" placeholder="SDT" value={form.phoneNumber} onChange={(event) => onChangePhone(event)} />
                                </Form.Group>
                                <button type="submit" className="btn btn-primary mr-2" style={{ width: 100, height: 50 }} onClick={() => onUpdate()}>Cập nhật</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    authState: get(state, 'authState'),
    userInfo: get(state, 'authState.userInfo.data', {}),
    workSpaces: get(state, 'authState.workSpaces.data', []),
});

const mapDispatchToProps = {
    actionLogOut,
    actionGetUserMetadata,
    actionGetWorkspace
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileUser))
