export * from './router.constant';

export const TYPES_INPUT = {
    SELECT_COUNTRY: 'SELECT_COUNTRY',
    SELECT_MAJOR_EMOMIC: 'SELECT_MAJOR_EMOMIC',
    INPUT_TEXT: 'INPUT_TEXT'
}

export const DATE_FORMAT_DD_MM_YYY = 'DD-MM-YYYY, h:mm:ss a';


export const DATA_ECOMIC_MAJOR = [
    {
        id: 0,
        name: '-...'
    },
    {
        id: 1,
        name: 'NÔNG NGHIỆP, LÂM NGHIỆP VÀ THUỶ SẢN'
    },
    {
        id: 2,
        name: 'KHAI KHOÁNG'
    },
    {
        id: 3,
        name: 'CÔNG NGHIỆP CHẾ BIẾN, CHẾ TẠO'
    },
    {
        id: 4,
        name: 'SẢN XUẤT VÀ PHÂN PHỐI ĐIỆN, KHÍ ĐỐT, NƯỚC NÓNG, HƠI NƯỚC VÀ ĐIỀU HOÀ KHÔNG KHÍ'
    },
    {
        id: 5,
        name: 'CUNG CẤP NƯỚC; HOẠT ĐỘNG QUẢN LÝ VÀ XỬ LÝ RÁC THẢI, NƯỚC THẢI'
    },
    {
        id: 6,
        name: 'XÂY DỰNG'
    },
    {
        id: 7,
        name: 'BÁN BUÔN VÀ BÁN LẺ; SỬA CHỮA Ô TÔ, MÔ TÔ, XE MÁY VÀ XE CÓ ĐỘNG CƠ KHÁC'
    },
    {
        id: 8,
        name: 'VẬN TẢI KHO BÃI'
    },
    {
        id: 9,
        name: 'DỊCH VỤ LƯU TRÚ VÀ ĂN UỐNG'
    },
    {
        id: 10,
        name: 'THÔNG TIN VÀ TRUYỀN THÔNG'
    },
    {
        id: 11,
        name: 'HOẠT ĐỘNG TÀI CHÍNH, NGÂN HÀNG VÀ BẢO HIỂM'
    },
    {
        id: 12,
        name: 'HOẠT ĐỘNG KINH DOANH BẤT ĐỘNG SẢN'
    },
    {
        id: 13,
        name: 'HOẠT ĐỘNG CHUYÊN MÔN, KHOA HỌC VÀ CÔNG NGHỆ'
    },
    {
        id: 14,
        name: 'HOẠT ĐỘNG HÀNH CHÍNH VÀ DỊCH VỤ HỖ TRỢ'
    },
    {
        id: 15,
        name: 'HOẠT ĐỘNG CỦA ĐẢNG CỘNG SẢN, TỔ CHỨC CHÍNH TRỊ - XÃ HỘI, QUẢN LÝ NHÀ NƯỚC, AN NINH QUỐC PHÒNG; BẢO ĐẢM XÃ HỘI BẮT BUỘC'
    },
    {
        id: 16,
        name: 'GIÁO DỤC VÀ ĐÀO TẠO'
    },
    {
        id: 17,
        name: 'Y TẾ VÀ HOẠT ĐỘNG TRỢ GIÚP XÃ HỘI'
    },
    {
        id: 18,
        name: 'NGHỆ THUẬT, VUI CHƠI VÀ GIẢI TRÍ'
    },
    {
        id: 19,
        name: 'HOẠT ĐỘNG DỊCH VỤ KHÁC'
    },
    {
        id: 20,
        name: 'HOẠT ĐỘNG LÀM THUÊ CÁC CÔNG VIỆC TRONG CÁC HỘ GIA ĐÌNH, SẢN XUẤT SẢN PHẨM VẬT CHẤT VÀ DỊCH VỤ TỰ TIÊU DÙNG CỦA HỘ GIA ĐÌNH'
    },
    {
        id: 21,
        name: 'Khác'
    },
];


export const DATA_COUNTIRES = [
    {
        id: 0,
        name: "- Nước/tổ chức ..."
    },
    {
        id: 1,
        name: 'Việt Nam'
    },
    {
        id: 2,
        name: 'Thái Lan'
    },
    {
        id: 3,
        name: 'Ấn Độ'
    },
    {
        id: 4,
        name: 'Anh'
    },
    {
        id: 5,
        name: 'Bỉ'
    },
    {
        id: 6,
        name: 'Campuchia'
    },
    {
        id: 7,
        name: 'Canada'
    },
    {
        id: 8,
        name: 'Hà Lan'
    },
    {
        id: 9,
        name: 'Hàn Quốc'
    },
    {
        id: 10,
        name: 'Hoa Kì'
    },
    {
        id: 11,
        name: 'Hong Kong'
    },
    {
        id: 13,
        name: 'Hy Lạp'
    },
    {
        id: 14,
        name: 'Indonesia'
    },
    {
        id: 15,
        name: 'Isarel'
    },
    {
        id: 16,
        name: 'Italy'
    },
    {
        id: 17,
        name: 'Lào'
    },
    {
        id: 18,
        name: 'Liên Minh Châu Âu'
    },
    {
        id: 19,
        name: 'Malaysia'
    },
    {
        id: 20,
        name: 'Myanmar'
    },
    {
        id: 21,
        name: 'Nga'
    },
    {
        id: 22,
        name: 'Nhật Bản'
    },
    {
        id: 23,
        name: 'Pháp'
    },
    {
        id: 24,
        name: 'Philippine'
    },
    {
        id: 25,
        name: 'Singapore'
    },
    {
        id: 26,
        name: 'Tây Ban Nha'
    },
    {
        id: 27,
        name: 'Thổ Nhĩ Kỳ'
    },
    {
        id: 28,
        name: 'Thuỵ Sĩ'
    },
    {
        id: 29,
        name: 'Thụy Điển'
    },
    {
        id: 30,
        name: 'Trung Quốc'
    },
    {
        id: 31,
        name: 'Úc'
    },
    {
        id: 32,
        name: 'Ủy Ban Châu Âu EC'
    },
    {
        id: 33,
        name: 'Uzbekistan'
    },
    {
        id: 34,
        name: 'Đài Loan'
    },
    {
        id: 35,
        name: 'Đức'
    },
    {
        id: 36,
        name: 'Khác'
    },
];

export const DATA_REPORT_LIST = [
    {
        id: "report8",
        title: "CHỈ TIÊU THỐNG KÊ NGÀNH KHOA HỌC VÀ CÔNG NGHỆ",
        alias: "Biểu 08",
        order: 8
    },
    {
        id: "report1",
        alias: "Biểu 01",
        title: "Biểu 01/KHCN-TC - SỐ TỔ CHỨC KHOA HỌC VÀ CÔNG NGHỆ",
        order: 1
    },
    {
        id: "report2",
        alias: "Biểu 02",
        order: 2,
        title: "Biểu 02/KHCN-NL - SỐ NGƯỜI TRONG CÁC TỔ CHỨC KHOA HỌC VÀ CÔNG NGHỆ"
    },
    {
        id: "report3",
        order: 3,
        alias: "Biểu 03",
        title: "Biểu 03/KHCN-CP - CHI CHO KHOA HỌC VÀ CÔNG NGHỆ",
    },
    {
        id: "report4",
        alias: "Biểu 04",
        title: "Biểu 04/KHCN-NV - NHIỆM VỤ KHOA HỌC VÀ CÔNG NGHỆ",
        order: 4,
    },
    {
        id: "report5",
        order: 5,
        title: "Biểu 05/KHCN-HTQT - HỢP TÁC QUỐC TẾ VỀ KHOA HỌC VÀ CÔNG NGHỆ",
        alias: "Biểu 05"
    },
    {
        id: "report6",
        title: "Biểu 06/KHCN-CG - CHUYỂN GIAO CÔNG NGHỆ",
        alias: "Biểu 06",
        order: 6
    },
    {
        id: "report7",
        alias: "Biểu 07",
        order: 7,
        title: "Biểu 07/KHCN-DN - DOANH NGHIỆP VÀ THỊ TRƯỜNG KHOA HỌC VÀ CÔNG NGHỆ"
    },
    {
        id: "report9",
        title: "Biểu 9/KHCN-TĐC/TIÊU CHUẨN ĐO LƯỜNG CHẤT LƯỢNG ",
        alias: "Biểu 09",
        order: 9
    },
    {
        id: "report10",
        order: 10,
        title: "Biểu 10/KHCN-NLAT - NĂNG LƯỢNG NGUYÊN TỬ, AN TOÀN BỨC XẠ VÀ HẠT NHÂN",
        alias: "Biểu 10"
    }
];