import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import {
  CommonTableHeaderReport,
  CommonTableFooterReport, HeaderExport,
  FooterUpload, inputStyle,
  SelectCustom, InputCustom
} from 'app/components';
import { get, isEmpty } from 'lodash';
import { getUserId } from 'app/utils';
import { connect } from 'react-redux';
import { actionGetStorageReportDetail } from 'app/redux/actions';
import { api } from 'app/utils';
import Spinner from 'app/shared/Spinner';
import parse from 'html-react-parser';
import shortid from 'shortid';
import { DATA_ECOMIC_MAJOR, TYPES_INPUT, DATA_COUNTIRES } from 'app/constants';
import * as FileSaver from 'file-saver';
import { useRouter } from 'app/hooks';
import { Routers } from 'app/constants'

function ReportDetail(props) {

  const { location, actionViewtemplate, reportState, tableData = [],
    idReportUser, isGetttingReportDetail, reportDetailTitle,
    reportDetailHeader, reportDetailFooter, idCanNotRemove, idDisabledInput } = props;
  const router = useRouter();

  const { idReport, year, followUserId } = get(location, 'state');
  const userId = followUserId ? followUserId : getUserId();

  if (!idReport) {
    router.push(Routers.REPORT);
  }

  const isLoadingUploadFile = get(reportState, 'isLoadingUploadFile');
  const [dynamicTableData, setDynamicTableData] = useState([]);

  const [listidCanNotRemove, setListidCanNotRemove] = useState([]);
  const [exportting, setExportting] = useState(false);

  const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();

  const onSubmit = async () => {
    const reqData = { userId, idReportUser, rawData: dynamicTableData };
    api('reports/updateDataSaved', reqData);
  }

  const onChangeTimeFooter = ({ dateValue, monthValue }) => {
    if (dateValue && monthValue)
      api('reports/updateDateMonthSaved', { userId, idReportUser, date: dateValue, month: monthValue });
  }

  const inputChange = async ({ event, id, idxRow, idxCol }) => {
    const valueText = event.target.value ? parseInt(event.target.value) : 0;
    let change = [...dynamicTableData];
    setValue(id, valueText);
    change[idxRow].rowCols[idxCol].value = valueText;

    const code = change[idxRow].rowCols[1].title;
    change[0].rowCols[2].value = '';
    change[4].rowCols[2].value = '';
    change[7].rowCols[2].value = '';
    change[16].rowCols[2].value = '';
    change[22].rowCols[2].value = '';
    change[35].rowCols[2].value= '';
    change[41].rowCols[2].value = '';
    change[44].rowCols[2].value = '';
    change[56].rowCols[2].value = '';


    // if (code === '101' || code === '102' || code === '103') {
    //   const sum = parseInt(change[1].rowCols[2].value) + parseInt(change[2].rowCols[2].value) + parseInt(change[3].rowCols[2].value);
    //   setValue(change[0].rowCols[2].id, sum);
    //   change[0].rowCols[2].value = sum;
    // }

    // if (code === '201' || code === '202') {
    //   const sum = parseInt(change[5].rowCols[2].value) + parseInt(change[6].rowCols[2].value);
    //   setValue(change[4].rowCols[2].id, sum);
    //   change[4].rowCols[2].value = sum;
    // }

    // if (code === '301' || code === '302') {
    //   const sum = parseInt(change[8].rowCols[2].value) + parseInt(change[9].rowCols[2].value);
    //   setValue(change[7].rowCols[2].id, sum);
    //   change[7].rowCols[2].value = sum;
    // }

    // if (code === '401' || code === '402' || code === '403' || code === '404' || code === '405') {
    //   const sum = parseInt(change[11].rowCols[2].value) + parseInt(change[12].rowCols[2].value) + parseInt(change[13].rowCols[2].value) + parseInt(change[14].rowCols[2].value) + parseInt(change[15].rowCols[2].value);
    //   setValue(change[10].rowCols[2].id, sum);
    //   change[10].rowCols[2].value = sum;
    // }

    // if (code === '501' || code === '502' || code === '503' || code === '504' || code === '505') {
    //   const sum = parseInt(change[17].rowCols[2].value) + parseInt(change[18].rowCols[2].value) + parseInt(change[19].rowCols[2].value) + parseInt(change[20].rowCols[2].value) + parseInt(change[21].rowCols[2].value);
    //   setValue(change[16].rowCols[2].id, sum);
    //   change[16].rowCols[2].value = sum;
    // }

    // if (code === '601' || code === '602' || code === '603' || code === '604' || code === '605' || code === '606' || code === '607' || code === '608' || code === '609' || code === '610' || code === '611' || code === '612') {
    //   const sum = parseInt(change[23].rowCols[2].value) + parseInt(change[24].rowCols[2].value) + parseInt(change[25].rowCols[2].value) + parseInt(change[26].rowCols[2].value) + parseInt(change[27].rowCols[2].value)
    //     + parseInt(change[28].rowCols[2].value) + parseInt(change[29].rowCols[2].value) + parseInt(change[30].rowCols[2].value) + parseInt(change[31].rowCols[2].value) + parseInt(change[32].rowCols[2].value) + parseInt(change[33].rowCols[2].value) + parseInt(change[34].rowCols[2].value);
    //   setValue(change[22].rowCols[2].id, sum);
    //   change[22].rowCols[2].value = sum;
    // }

    // if (code === '701' || code === '702' || code === '703' || code === '704' || code === '705') {
    //   const sum = parseInt(change[36].rowCols[2].value) + parseInt(change[37].rowCols[2].value) + parseInt(change[38].rowCols[2].value) +
    //     + parseInt(change[39].rowCols[2].value) + parseInt(change[40].rowCols[2].value);
    //   setValue(change[35].rowCols[2].id, sum);
    //   change[35].rowCols[2].value = sum;
    // }

    // if (code === '801' || code === '802') {
    //   const sum = parseInt(change[42].rowCols[2].value) + parseInt(change[43].rowCols[2].value);
    //   setValue(change[41].rowCols[2].id, sum);
    //   change[41].rowCols[2].value = sum;
    // }

    // if (code === '901' || code === '902' || code === '903' || code === '904' || code === '905' || code === '906' || code === '907' || code === '908' || code === '909' || code === '910' || code === '911') {
    //   const sum = parseInt(change[45].rowCols[2].value) + parseInt(change[46].rowCols[2].value) + parseInt(change[47].rowCols[2].value) + parseInt(change[48].rowCols[2].value) + parseInt(change[49].rowCols[2].value)
    //     + parseInt(change[50].rowCols[2].value) + parseInt(change[51].rowCols[2].value) + parseInt(change[52].rowCols[2].value) + parseInt(change[53].rowCols[2].value) + parseInt(change[54].rowCols[2].value) + parseInt(change[55].rowCols[2].value);
    //   setValue(change[44].rowCols[2].id, sum);
    //   change[44].rowCols[2].value = sum;
    // }

    // if (code === '1001' || code === '1002' || code === '1003' || code === '1004' || code === '1005' || code === '1006') {
    //   const sum = parseInt(change[57].rowCols[2].value) + parseInt(change[58].rowCols[2].value) + parseInt(change[59].rowCols[2].value) + parseInt(change[60].rowCols[2].value) + parseInt(change[61].rowCols[2].value) + parseInt(change[62].rowCols[2].value);
    //   setValue(change[56].rowCols[2].id, sum);
    //   change[56].rowCols[2].value = sum;
    // }

    //save to DB
    setDynamicTableData(change);
    const reqData = { valueText, userId, idReportUser, rawData: change };
    api('reports/updateDataSaved', reqData);
  };

  const onAddRow = ({ idxRow, idxCol }) => {
    let newChanged = [...dynamicTableData];
    let rowAddData = { ...newChanged[idxRow] };
    rowAddData.rowCols = rowAddData.rowCols.map(item => {
      return { ...item, id: shortid.generate() }
    })
    newChanged.splice(idxRow + 1, 0, rowAddData);
    setDynamicTableData(newChanged);
    api('reports/updateDataSaved', { userId, idReportUser, rawData: newChanged });
  }

  const onDeleteRow = ({ idxRow, idxCol }) => {
    let newChanged = [...dynamicTableData];
    newChanged.splice(idxRow, 1);
    setDynamicTableData(newChanged);
    api('reports/updateDataSaved', { userId, idReportUser, rawData: newChanged });
  }

  const onRowChangeSelect = ({ idxRow, idxCol, newVal, type }) => {
    let newChanged = [...dynamicTableData];
    newChanged[idxRow].rowCols[idxCol].value = newVal;
    if (type == TYPES_INPUT.SELECT_COUNTRY) {
      const find = DATA_COUNTIRES.find(country => country.id == newVal) || DATA_COUNTIRES[0];
      newChanged[idxRow].rowCols[idxCol].title = get(find, 'name');
    }
    if (type == TYPES_INPUT.SELECT_MAJOR_EMOMIC) {
      const find = DATA_ECOMIC_MAJOR.find(country => country.id == newVal) || DATA_ECOMIC_MAJOR[0];
      newChanged[idxRow].rowCols[idxCol].title = get(find, 'name');
    }
    setDynamicTableData(newChanged);
    const reqData = { userId, idReportUser, rawData: newChanged };
    api('reports/updateDataSaved', reqData);
  }

  const onExportExcel = async () => {
    try {
      setExportting(true);
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const fileName = 'Bieu08_KHCN_QC';
      const response = await api('reports/exportExcel', {
        dataList: dynamicTableData,
        idReport: idReport
      });
      const buffer = get(response, 'data.buffer.data');
      const arr = new Uint8Array(buffer);
      const dataFile = new Blob([arr], { type: fileType });
      FileSaver.saveAs(dataFile, fileName + fileExtension);
    } catch (e) {
      console.log('onExportExcel error', e)
    }
    finally {
      setExportting(false);
    }
  }

  const analysisReport = async () => {
    try {
      setExportting(true);
      let sumInput = 0, sumTyped = 0;
      let ratio = 0;
      if (!dynamicTableData) return;
      dynamicTableData.map(row => {
        row.rowCols.map(col => {
          const { type, value } = col;
          if (type === 'input' && value > 0) {
            sumTyped++;
          }
          if (type === 'input') {
            sumInput++;
          }
        })
      });
      if (sumTyped !== 0){
        ratio = Number(sumTyped / sumInput);
      }
      const data = await api('reports/analysisByUser', {
        userId: userId,
        idReport: idReport,
        ratio: ratio,
        year: year
      });
      return data;
    } catch (e) {

    } finally {
      setExportting(false);
    }
  }

  const onFinishReport = async () => {
    await analysisReport();
    router.push("/report")
  }

  useEffect(() => {
    if (tableData) {
      setDynamicTableData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (idCanNotRemove) {
      setListidCanNotRemove(idCanNotRemove);
    }
  }, [idCanNotRemove]);

  useEffect(() => {
    actionViewtemplate({ idReport, year, userId });
    //analysisReport();
  }, []);


  if (isGetttingReportDetail || exportting || isLoadingUploadFile) {
    return <Spinner />
  }


  return (
    <div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <HeaderExport onExportExcel={() => onExportExcel()} />
              <div className="table-responsive">
                {/* <CommonTableHeaderReport data={reportDetailHeader} title={reportDetailTitle} /> */}
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td className="vertical-align" align="center" rowspan="1" colspan="1">
                        <b>HỆ THỐNG CHỈ TIÊU THỐNG KÊ NGÀNH KHOA HỌC VÀ CÔNG NGHỆ<br /></b>
                        <p>(Theo Thông tư số 03/2018/TT-BKHCN ngày 15 tháng 5 năm 2018 của Bộ trưởng Bộ Khoa học và Công nghệ)</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1" width="40%"></td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">Mã số</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">Tổng số</td>
                      </tr>

                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">A	</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">B</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1">C</td>
                      </tr>

                      {dynamicTableData && dynamicTableData.map((row, idxRow) => {
                        return (
                          <tr key={idxRow}>
                            {get(row, 'rowCols', []).map((col, idxCol) => {
                              const { id, type } = col || {};
                              if (type === 'title') return (<td className="vertical-align" rowspan="1" colspan="1" >{parse(get(col, 'title'))}</td>);
                              if (type === 'code') return (<td className="vertical-align" rowspan="1" align="center" colspan="1"> {parse(get(col, 'title', ''))}</td>);
                              if (type === 'input') {
                                return (
                                  <td className="vertical-align" align="center" rowspan="1" colspan="1">
                                    <input type="number"
                                      readOnly={idDisabledInput.includes(id) || userId === 'admin' || followUserId}
                                      {...register(get(col, 'id'))}
                                      name={get(col, 'id')}
                                      min={0}
                                      id={get(col, 'id')}
                                      class="form-control-sm form-control form-input"
                                      style={inputStyle}
                                      defaultValue={idDisabledInput.includes(id) ? '' : get(col, 'value', 0)}
                                      onChange={event => inputChange({ event, id, idxRow, idxCol })}
                                    />
                                  </td>
                                )
                              }
                              if (type === TYPES_INPUT.SELECT_MAJOR_EMOMIC) {
                                return (
                                  <SelectCustom
                                    options={DATA_ECOMIC_MAJOR}
                                    onAdd={() => onAddRow({ idxRow, idxCol })}
                                    onDelelele={() => onDeleteRow({ idxRow, idxCol })}
                                    onChangeSelect={(newVal) => onRowChangeSelect({ idxRow, idxCol, newVal })}
                                    defaultValue={get(col, 'value', '')}
                                    canNotDeleteRow={listidCanNotRemove.includes(id)}
                                  />

                                )
                              }
                              if (type === TYPES_INPUT.SELECT_COUNTRY) {
                                return (
                                  <SelectCustom
                                    options={DATA_COUNTIRES}
                                    onAdd={() => onAddRow({ idxRow, idxCol })}
                                    onDelelele={() => onDeleteRow({ idxRow, idxCol })}
                                    onChangeSelect={(newVal) => onRowChangeSelect({ idxRow, idxCol, newVal })}
                                    defaultValue={get(col, 'value')}
                                    canNotDeleteRow={listidCanNotRemove.includes(id)}
                                  />

                                )
                              }
                              if (type === TYPES_INPUT.INPUT_TEXT) {
                                return (
                                  <InputCustom
                                    onAdd={() => onAddRow({ idxRow, idxCol })}
                                    onDelelele={() => onDeleteRow({ idxRow, idxCol })}
                                    onChangeText={(newVal) => onRowChangeSelect({ idxRow, idxCol, newVal })}
                                    defaultValue={get(col, 'value')}
                                    canNotDeleteRow={listidCanNotRemove.includes(id)}
                                  />

                                )
                              }
                              return (<td></td>);

                            })}
                          </tr>

                        )
                      })}
                    </tbody>
                  </table>
                </form>
                <CommonTableFooterReport data={reportDetailFooter} onChangeTime={onChangeTimeFooter} />
                <FooterUpload onFinishReport={onFinishReport} storageID={idReportUser}
                  fileUploaded={get(reportState, 'reportDetail.data.fileUpload')}
                  onFinishUpload={() => actionViewtemplate({ idReport, year, userId })}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportState: get(state, 'reportState'),
  tableData: get(state, 'reportState.reportDetail.data.rawData', []),
  sizeCols: get(state, 'reportState.reportDetail.data.size', 1),
  idCanNotRemove: get(state, 'reportState.reportDetail.data.idCanNotRemove', []),
  idDisabledInput: get(state, 'reportState.reportDetail.data.idDisabledInput', []),
  idReportUser: get(state, 'reportState.reportDetail.data.id'),
  isGetttingReportDetail: get(state, 'reportState.isGetttingReportDetail'),
  reportDetailHeader: get(state, 'reportState.reportDetail.data.headers', {}),
  reportDetailTitle: get(state, 'reportState.reportDetail.data.title', ''),
  reportDetailFooter: get(state, 'reportState.reportDetail.data.footer', {}),

});

const mapDispatchToProps = {
  actionViewtemplate: actionGetStorageReportDetail
};


export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail)
