import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { actionGetUserMetadata, actionGetAnalysis, actionGetWorkspace, actionGetAllRole, actionGetAllReports } from 'app/redux/actions';
import { withRouter } from 'react-router-dom';
import Spinner from 'app/shared/Spinner';
import { ProgressBar } from 'react-bootstrap';
import { api, getUserId } from 'app/utils';
import { DATA_REPORT_LIST } from 'app/constants'

const options =
{
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true
      }
    }]
  },
  legend: {
    display: false
  },
  elements: {
    point: {
      radius: 0
    }
  }

};


function AdminDashboardByReport(props) {

  const { authState, actionGetUserMetadata, actionGetAnalysis, history, reportsState } = props || {};
  const { isGetttingAnalysis } = reportsState || false;

  const [dataTable, setDataTable] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const userId = getUserId();

   

  const fetchDashboardByReport = async () => {
    try {
      setLoading(true);
      const res = await api('admin/dashboardByReport', {
        userId: 'admin',
        securityKey: 'KHCNQB',
        year: 2021
      });

      const { data } = res;
      const dataAPI = get(data, 'data', []);
      let dataChart = [];
      dataAPI.map((item, idx) => {
        const { id, average } = item || {};
        const findTitle = DATA_REPORT_LIST.find(rp => rp.id === id);
        const { title } = findTitle || '';
        dataChart.push(
          {
            id,
            average,
            title
          }
        )
      });

      setDataTable(dataChart);
    } catch (e) {

    }
    finally {
      setLoading(false);
    }

  }


  useEffect(() => {
    fetchDashboardByReport();
  }, []);
 

  if (isGetttingAnalysis || isLoading) {
    return <Spinner style={{ position: null }} />;
  }

  return (
    <div>
      <div className="row">
        
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Thống kê số liệu theo báo cáo</h4>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Tên biểu </th>
                      <th> Tiến độ </th>
                      <th> Số % </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTable && dataTable.map(item => {
                      return (
                        <tr>
                          <td>{get(item, 'title')} </td>
                          <td>
                            <ProgressBar variant="success" now={get(item, 'average', 0)} />
                          </td>
                          <td> {get(item, 'average', 0) ? get(item, 'average', 0).toFixed(2) : 0}% </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportsState: get(state, 'reportState'),
});

const mapDispatchToProps = {
  actionGetUserMetadata,
  actionGetAnalysis,
  actionGetWorkspace,
  actionGetAllReports
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDashboardByReport))
