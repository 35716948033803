import React, { useState, useEffect, useCallback } from 'react'
import { ProgressBar } from 'react-bootstrap';
import Modal from 'react-modal';
import { api, getUserId } from 'app/utils';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT_DD_MM_YYY, Routers } from 'app/constants';
import Spinner from 'app/shared/Spinner';
import { useRouter } from 'app/hooks';
import storage from '../firebase';


function GuidePage(props) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const userId = getUserId();
  const [urlFile, setUrlFile] = useState('');

 

  const getURLGuideFile = useCallback(async ()=>{
    const url = await storage.ref('files/Tai-lieu-huong-dan.pdf').getDownloadURL();
    if(url){
      setUrlFile(url);
    }
  },[]);

  useEffect(() => {
    getURLGuideFile();
  },[])



  if (isLoading) {
    return <Spinner />
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Tài liệu hướng dẫn</h3>
      </div>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div class="container">
            <iframe src={urlFile} width={'100%'} height={1000}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GuidePage;
