import {
  ActionsType
} from '../actions';

export const authReducer = (state = {}, action) => {
  const { type, payload } = action || {};
  switch (type) {
    case ActionsType.LOGIN_USER_REQUEST: {
      return {
        ...state,
        loginFetching: true
      }
    }
    case ActionsType.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        loginFetching: false,
        userLoged: payload
      }
    }
    case ActionsType.LOGIN_USER_FAIL: {
      return {
        ...state,
        loginFetching: false,
        errorLogin: payload
      }
    }

    case ActionsType.GET_USER_META_REQUEST: {
      return {
        ...state,
        getUserMetaFetching: true
      }
    }
    case ActionsType.GET_USER_META_SUCCESS: {
      return {
        ...state,
        getUserMetaFetching: false,
        userInfo: payload
      }
    }
    case ActionsType.GET_USER_META_FAIL: {
      return {
        ...state,
        getUserMetaFetching: false,
        userInfoError: payload
      }
    }

    case ActionsType.GET_WORK_SPACE_REQUEST: {
      return {
        ...state,
        gettingWorkspace: true
      }
    }
    case ActionsType.GET_WORK_SPACE_SUCCESS: {
      return {
        ...state,
        gettingWorkspace: false,
        workSpaces: payload
      }
    }
    case ActionsType.GET_WORK_SPACE_FAIL: {
      return {
        ...state,
        gettingWorkspace: false,
        errorWorkSpaces: payload
      }
    }

    case ActionsType.GET_ROLES_REQUEST: {
      return {
        ...state,
        gettingRoles: true
      }
    }
    case ActionsType.GET_ROLES_SUCCESS: {
      return {
        ...state,
        gettingRoles: false,
        roles: payload
      }
    }
    case ActionsType.GET_ROLES_FAIL: {
      return {
        ...state,
        gettingRoles: false,
        errorRoles: payload
      }
    }

    case ActionsType.GET_ALL_REPORTS_REQUEST: {
      return {
        ...state,
        gettingReports: true
      }
    }
    case ActionsType.GET_ALL_REPORTS_SUCCESS: {
      return {
        ...state,
        gettingReports: false,
        listReports: payload
      }
    }
    case ActionsType.GET_ALL_REPORTS_FAIL: {
      return {
        ...state,
        gettingReports: false,
        erorrListReports: payload
      }
    }
    default: return state;
  }
};


export default authReducer;
