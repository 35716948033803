import React, { Component } from 'react';
// import { Trans } from 'react-i18next';
import PropTypes from 'prop-types'

function HeaderExport(props) {
    const { onExportExcel, onExportWord } = props;

    return (
        <div class="row">
            <div class="col-sm-6">
                <p>(Dữ liệu sẽ được tự động lưu lại sau khi nhập liệu.)</p>
            </div>
            <div class="col-sm-6 text-right">
                <label>Xuất biểu mẫu: </label>
                {/* <button type="submit" name="submit" value="export_docx" class="btn btn-primary btn-sm  ml-2 mb-2" onClick={onExportWord}><i class="mdi mdi-file-word-box"></i> Word</button> */}
                <button type="submit" name="submit" value="export_excel" class="btn btn-primary btn-sm ml-2 mb-2" onClick={onExportExcel}><i class="mdi mdi-file-excel"></i> Excel</button>
            </div>
        </div>
    );

}

export default HeaderExport;

HeaderExport.defaultProps = {
    onExportExcel: () => { },
    onExportWord: () => { },
};

HeaderExport.propTypes = {
    onExportExcel: PropTypes.func.isRequired,
    onExportWord: PropTypes.func.isRequired,
}