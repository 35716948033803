import { combineReducers } from 'redux';


import { authReducer } from './auth.reducer';
import { reportReducer } from './report.reducer';

export default combineReducers({
    authState: authReducer,
    reportState: reportReducer
});
