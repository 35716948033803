const TYPES = {
    LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
    LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_USER_FAIL: 'LOGIN_USER_FAIL',
    LOGOUT_USER: 'LOGOUT_USER',
    GET_USER_META_REQUEST: 'GET_USER_META_REQUEST',
    GET_USER_META_SUCCESS: 'GET_USER_META_SUCCESS',
    GET_USER_META_FAIL: 'GET_USER_META_FAIL',

    GET_ANALYSIS_REQUEST: 'GET_ANALYSIS_REQUEST',
    GET_ANALYSIS_SUCCESS: 'GET_ANALYSIS_SUCCESS',
    GET_ANALYSIS_FAIL: 'GET_ANALYSIS_FAIL',

    GET_STORAGE_REPORT_DETAIL_REQUEST: 'GET_STORAGE_REPORT_DETAIL_REQUEST',
    GET_STORAGE_REPORT_DETAIL_SUCCESS: 'GET_STORAGE_REPORT_DETAIL_SUCCESS',
    GET_STORAGE_REPORT_DETAIL_FAIL: 'GET_STORAGE_REPORT_DETAIL_FAIL',

    GET_WORK_SPACE_REQUEST: 'GET_WORK_SPACE_REQUEST',
    GET_WORK_SPACE_SUCCESS: 'GET_WORK_SPACE_SUCCESS',
    GET_WORK_SPACE_FAIL: 'GET_WORK_SPACE_FAIL',

    GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAIL: 'GET_ROLES_FAIL',

    GET_ALL_REPORTS_REQUEST: 'GET_ALL_REPORTS_REQUEST',
    GET_ALL_REPORTS_SUCCESS: 'GET_ALL_REPORTS_SUCCESS',
    GET_ALL_REPORTS_FAIL: 'GET_ALL_REPORTS_FAIL',

    LOADING_UPLOAD_FILE: 'LOADING_UPLOAD_FILE',
    ENDED_LOADING_UPLOAD_FILE: 'ENDED_LOADING_UPLOAD_FILE',
}

export default TYPES;