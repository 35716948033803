import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { api } from 'app/utils';
import axios from 'axios';
import { API_URL, APP_ENV } from 'app/configs';
import { get } from 'lodash';
import { getUserId } from 'app/utils';
import { actionLoadingUploadFile, actionEndedLoadingUploadFile } from 'app/redux/actions';
import storage from './../../firebase';

function FooterUpload(props) {
    const { onFinishReport, storageID, fileUploaded,
        actionLoadingUploadFile,
        actionEndedLoadingUploadFile,
        onFinishUpload } = props;


    const [selectedFile, setSelectedFile] = useState(null);
    const userId = getUserId();

    const onFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };

    const onFileUpload = async () => {
        try {
            if (selectedFile == null) {
                return;
            }
            actionLoadingUploadFile();
            const result = await storage.ref(`/files/${selectedFile.name}`).put(selectedFile);
            const { ref } = result;
            const url = await ref.getDownloadURL();
            if (!url) return;
            const respSaveCloud = await api('reports/storageFile', { data: { url, fileName: selectedFile.name }, userId, updated: new Date(), storageID });

        } catch (e) {
            console.log('onFileUpload', e)
        }
        finally {
            actionEndedLoadingUploadFile();
            onFinishUpload();
        }

    };


    return (
        <div class="row vertical-align" style={{ marginTop: 10, paddingTop: 10, paddingBottom: 10 }}>
            <div class="col-sm-9">
                <label>Tải file đã ký, có dấu </label>
                <label htmlFor="upload-file" class="btn btn-primary btn-sm ml-2 mb-2" style={{ opacity: userId === 'admin' ? 0.5 : 1 }}><i class="mdi mdi-laptop"  ></i> Chọn file PDF</label>
                <input style={{ display: "none" }} type="file" id="upload-file" onChange={(e) => onFileChange(e)} accept="application/pdf" disabled={userId === 'admin'} />
                {selectedFile && <span className="ml-2">{selectedFile?.name}</span>}
                {selectedFile && <button name="submit" disabled={!selectedFile || userId === 'admin'} value="upload_document" class="btn  btn-primary btn-sm ml-2 mb-2" onClick={() => onFileUpload()}><i class="mdi mdi-cloud-upload"></i> Tải file</button>}

                <div>
                    {fileUploaded && <a target="_blank" href={get(fileUploaded, 'directLink')}> <button type="button" className="btn btn-link"  >{get(fileUploaded, 'fileName')}</button></a>}
                </div>

            </div>
            <div class="col-sm-3 text-right">
                <button type="submit" name="submit" value="upload_document" class="btn  btn-primary btn-sm ml-2 mb-2" onClick={() => onFinishReport()}>
                    <i class="mdi mdi-content-save"></i> Kết thúc nhập biểu</button>
            </div>
        </div >

    );

}


const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    actionLoadingUploadFile,
    actionEndedLoadingUploadFile
};


export default connect(mapStateToProps, mapDispatchToProps)(FooterUpload)
FooterUpload.propTypes = {
    onFinishReport: PropTypes.func.isRequired,
    storageID: PropTypes.string.isRequired,
    onFinishUpload: PropTypes.func.isRequired,
}