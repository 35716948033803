import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';
import { get } from 'lodash'

const selectInputStyle = {
    width: '50px',
    display: 'inline-block',
    marginLeft: '4px',
    marginRight: '4px'
};

const selectInputStyleYear = {
    width: '70px',
    display: 'inline-block',
    marginLeft: '4px',
    marginRight: '4px'
};


function TableFooter(props) {
    const { onChangeTime, data } = props || {};
    const [dateValue, setDateValue] = useState('');
    const [monthValue, setMonthValue] = useState('');
    const [yearValue, setYearValue] = useState('');

    useEffect(() => {
        const defaultDate = get(data, 'date', moment().format('DD'));
        const defaultMonth = get(data, 'month', moment().format('MM'));
        const defaultYear = get(data, 'year', moment().format('YYYY'));
        setDateValue(defaultDate);
        setMonthValue(defaultMonth);
        setYearValue(defaultYear)
    }, [data])


    const onChangeDate = (e) => {
        setDateValue(e.target.value);
        onChangeTime({ dateValue: e.target.value, monthValue, yearValue });
    };

    const onChangeMonth = (e) => {
        setMonthValue(e.target.value);
        onChangeTime({ dateValue, monthValue: e.target.value, yearValue });
    };

    const onChangeYear = (e) => {
        setYearValue(e.target.value);
        onChangeTime({ dateValue, monthValue, yearValue: e.target.value });
    };

    return (
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <td className="vertical-align" align="center" rowspan="1" colspan="3"></td>
                    <td className="vertical-align" align="center" rowspan="1" colspan="4">
                        <div>
                            <i>
                                Quảng Bình, ngày
                                <select class="form-control auto-save-other-field" data-name="date"
                                    style={selectInputStyle} value={dateValue}
                                    onChange={(e) => onChangeDate(e)}
                                >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                </select>
                                tháng
                                <select class="form-control auto-save-other-field" data-name="month" style={selectInputStyle}
                                    value={monthValue}
                                    onChange={(e) => onChangeMonth(e)}
                                >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                                năm
                                <select class="form-control auto-save-other-field" data-name="year" style={selectInputStyleYear}
                                    value={yearValue}
                                    onChange={(e) => onChangeYear(e)}
                                >
                                    <option value="2018">2018</option>
                                    <option value="2019">2019</option>
                                    <option value="2020">2021</option>
                                    <option select value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                </select>
                            </i>
                            <br />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="vertical-align" align="center" rowspan="1" colspan="1" width="30%">
                        <div><b>Người lập biểu</b> <br /></div>
                        <div><i>(ký, ghi rõ họ tên)</i></div>
                    </td>
                    <td className="vertical-align" align="center" rowspan="1" colspan="2" width="30%">
                        <div>
                            <b>Người kiểm tra biểu</b> <i><br /></i>
                        </div>
                        <div><i>(ký, ghi rõ họ tên)</i></div>
                    </td>
                    <td className="vertical-align" align="center" rowspan="1" colspan="4">
                        <div>
                            <b>Thủ trưởng đơn vị</b> <i><br /></i>
                        </div>
                        <div><i>(Ký, ghi rõ họ tên và đóng dấu)</i></div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

TableFooter.propTypes = {
    onChangeTime: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
}

TableFooter.defaultProps = {
    onChangeTime: () => { },
    data: {}
}

export default TableFooter;