import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import {
  CommonTableHeaderReport,
  CommonTableFooterReport, HeaderExport,
  FooterUpload, inputStyle
} from 'app/components';
import { get } from 'lodash';
import { getUserId } from 'app/utils';
import { connect } from 'react-redux';
import { actionGetStorageReportDetail } from 'app/redux/actions';
import { api } from 'app/utils';
import Spinner from 'app/shared/Spinner';
import parse from 'html-react-parser';
import * as FileSaver from 'file-saver';
import { useRouter } from 'app/hooks';
import { Routers } from 'app/constants';

function ReportDetail(props) {
  const { location, actionViewtemplate, reportState, tableData,
    idReportUser, isGetttingReportDetail, reportDetailTitle,
    reportDetailHeader, reportDetailFooter } = props;

  const router = useRouter();
  const { idReport, year, followUserId } = get(location, 'state');

  if (!idReport) {
    router.push(Routers.REPORT);
  }

  const isLoadingUploadFile = get(reportState, 'isLoadingUploadFile');

  const userId = followUserId ? followUserId : getUserId();

  const [dynamicTableData, setDynamicTableData] = useState(tableData);
  const [idxRowsDisabled] = useState([0, 2, 4]);
  const [exportting, setExportting] = useState(false);
  const { register, handleSubmit, watch, formState: { errors }, setValue, getValues } = useForm();

  const onSubmit = async () => {
    const reqData = { userId, idReportUser, rawData: dynamicTableData };
    api('reports/updateDataSaved', reqData);
  }

  const onChangeTimeFooter = ({ dateValue, monthValue }) => {
    if (dateValue && monthValue)
      api('reports/updateDateMonthSaved', { userId, idReportUser, date: dateValue, month: monthValue });
  }

  const inputChange = async ({ event, id, idxRow, idxCol }) => {
    const valueText = event.target.value ? parseInt(event.target.value) : 0;
    let change = [...dynamicTableData];
    setValue(id, valueText);
    change[idxRow].rowCols[idxCol].value = valueText;

    //sum by row
    let sumRow2To5 = 0;
    for (let i = 3; i <= 6; i++) {
      sumRow2To5 += parseInt(change[idxRow].rowCols[i].value);
    }
    setValue(change[idxRow].rowCols[2].id, sumRow2To5);
    change[idxRow].rowCols[2].value = sumRow2To5;

    if (idxRow === 3) {//2.1. Chi thực hiện nhiệm vụ thường xuyên theo chức năng
      let sumbyrow = 0;
      for (let i = 2; i <= 6; i++) {
        sumbyrow = change[3].rowCols[i].value + change[4].rowCols[i].value;
        setValue(change[2].rowCols[i].id, sumbyrow);
        change[2].rowCols[i].value = sumbyrow;//2. Chi sự nghiệp KH&CN
      }
    }

    if (idxRow === 5 || idxRow === 6 || idxRow === 7 || idxRow === 8) {//2.2. Chi thực hiện nhiệm vụ KH&CN
      let sumbyrow = 0, sumMuc2 = 0;//2. Chi sự nghiệp KH&CN;
      for (let i = 2; i <= 6; i++) {
        sumbyrow = change[5].rowCols[i].value + change[6].rowCols[i].value + change[7].rowCols[i].value + change[8].rowCols[i].value;
        setValue(change[4].rowCols[i].id, sumbyrow);
        change[4].rowCols[i].value = sumbyrow;
        sumMuc2 = change[3].rowCols[i].value + change[4].rowCols[i].value;
        setValue(change[2].rowCols[i].id, sumMuc2);
        change[2].rowCols[i].value = sumMuc2;
      }
    }

    //sum by column
    let sumCol = 0;
    for (let i = 2; i <= 6; i++) {
      sumCol = parseInt(change[1].rowCols[i].value) + parseInt(change[2].rowCols[i].value) + parseInt(change[10].rowCols[i].value);
      setValue(change[0].rowCols[i].id, sumCol);
      change[0].rowCols[i].value = sumCol;
    }
    //save to DB
    setDynamicTableData(change);
    const reqData = { valueText, userId, idReportUser, rawData: change };
    api('reports/updateDataSaved', reqData);
  }

  const onExportExcel = async () => {
    try {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const fileName = 'Bieu03_KHCN_CP';
      const response = await api('reports/exportExcel', {
        dataList: dynamicTableData,
        idReport: idReport,
      });
      const buffer = get(response, 'data.buffer.data');
      const arr = new Uint8Array(buffer);
      const dataFile = new Blob([arr], { type: fileType });
      FileSaver.saveAs(dataFile, fileName + fileExtension);
    } catch (e) {
    }
    finally {
      setExportting(false);
    }
  }

  const onExportWord = (element, filename = '') => {
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + document.getElementById(element).innerHTML + postHtml;

    var blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });

    // Specify link url
    var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + '.doc' : 'document.doc';

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  }

  const analysisReport = async () => {
    try {
      setExportting(true);
      let sumInput = 0, sumTyped = 0;
      let ratio = 0;
      if (!dynamicTableData) return;
      dynamicTableData.map(row => {
        row.rowCols.map(col => {
          const { type, value } = col;
          if (type === 'input' && value > 0) {
            sumTyped++;
          }
          if (type === 'input') {
            sumInput++;
          }
        })
      });
      if (sumTyped !== 0){
        ratio = Number(sumTyped / sumInput);
      }
      const data = await api('reports/analysisByUser', {
        userId: userId,
        idReport: idReport,
        ratio: ratio,
        year: year
      });
      return data;
    } catch (e) {

    } finally {
      setExportting(false);
    }
  }

  const onFinishReport = async () => {
    await analysisReport();
    router.push("/report")
  }


  useEffect(() => {
    if (tableData) {
      setDynamicTableData(tableData);
    }
  }, [tableData]);


  useEffect(() => {
    actionViewtemplate({ idReport, year, userId });
    //analysisReport();
  }, []);


  if (isGetttingReportDetail || exportting || isLoadingUploadFile) {
    return <Spinner />
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> {reportDetailTitle}</h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <HeaderExport onExportExcel={() => onExportExcel()} onExportWord={() => onExportWord('sheetjs')} />
              <div className="table-responsive">
                <CommonTableHeaderReport data={reportDetailHeader} title={reportDetailTitle} userId={userId} />
                <form onSubmit={handleSubmit(onSubmit)}>
                  <table className="table table-bordered" id="sheetjs">
                    <tbody>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="3" colspan="1" width="20%"></td>
                        <td className="vertical-align" align="center" rowspan="3" colspan="1"  >Mã số</td>
                        <td className="vertical-align" align="center" rowspan="3" colspan="1" width="10%"  >Tổng số</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="4"  > Chia theo nguồn cấp kinh phí</td>
                      </tr>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="2"  >Ngân sách nhà nước	</td>
                        <td className="vertical-align" align="center" rowspan="2" colspan="1"  >Nguồn trong nước ngoài ngân sách nhà nước</td>
                        <td className="vertical-align" align="center" rowspan="2" colspan="1"  >Nguồn Nước ngoài</td>
                      </tr>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1"  >Trung ương</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1"  >Địa phương</td>
                      </tr>
                      <tr>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1"  >A	</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1"  >B</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1"  >1</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1"  >2</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1"  >3</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1"  >4</td>
                        <td className="vertical-align" align="center" rowspan="1" colspan="1"  >5</td>
                      </tr>

                      {tableData && tableData.map((row, idxRow) => {
                        return (

                          <tr>
                            {get(row, 'rowCols', []).map((col, idxCol) => {
                              const { id, type } = col || {};
                              if (type === 'title') return (<td className="vertical-align" rowspan="1" colspan="1"  >{parse(get(col, 'title'))}</td>);
                              if (type === 'code') return (<td className="vertical-align" rowspan="1" align="center" colspan="1"  >{get(col, 'title')}</td>);
                              if (type === 'input') {
                                return (
                                  <td className="vertical-align" align="center" rowspan="1" colspan="1"  >
                                    <input type="number"
                                      readOnly={idxRowsDisabled.includes(idxRow) || idxCol === 2 || userId === 'admin' || followUserId}
                                      {...register(get(col, 'id'))}
                                      name={get(col, 'id')}
                                      min={0}
                                      id={get(col, 'id')}
                                      class="form-control-sm form-control form-input"
                                      style={inputStyle}
                                      defaultValue={get(col, 'value', 0)}
                                      onChange={event => inputChange({ event, id, idxRow, idxCol })}
                                    />
                                    <p hidden>{get(col, 'value', 0)}</p>
                                  </td>
                                )
                              }
                              return (<td></td>);

                            })}
                          </tr>

                        )
                      })}
                    </tbody>
                  </table>
                </form>
                <CommonTableFooterReport data={reportDetailFooter} onChangeTime={onChangeTimeFooter} />
                <FooterUpload onFinishReport={onFinishReport} storageID={idReportUser}
                  fileUploaded={get(reportState, 'reportDetail.data.fileUpload')}
                  onFinishUpload={() => actionViewtemplate({ idReport, year, userId })}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportState: get(state, 'reportState'),
  tableData: get(state, 'reportState.reportDetail.data.rawData', []),
  sizeCols: get(state, 'reportState.reportDetail.data.size', 1),
  idReportUser: get(state, 'reportState.reportDetail.data.id'),
  isGetttingReportDetail: get(state, 'reportState.isGetttingReportDetail'),
  reportDetailHeader: get(state, 'reportState.reportDetail.data.headers', {}),
  reportDetailTitle: get(state, 'reportState.reportDetail.data.title', ''),
  reportDetailFooter: get(state, 'reportState.reportDetail.data.footer', {}),

});

const mapDispatchToProps = {
  actionViewtemplate: actionGetStorageReportDetail
};


export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail)
