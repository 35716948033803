import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { actionLogOut } from 'app/redux/actions';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { useToken } from 'app/hooks';
import { api, getUserId } from 'app/utils';
import { DATE_FORMAT_DD_MM_YYY } from 'app/constants';
import { useRouter } from 'app/hooks';
import { Routers } from 'app/constants';
import logoKhcn from '../../assets/images/logo-khcnqb.jpeg';


function Navbar(props) {

  const { actionLogOut, history } = props;
  const { setToken } = useToken();
  const userId = getUserId();
  const routers = useRouter();


  const [messagedLits, setMessagedResp] = useState([]);
  const [totalMessage, setTotalMessage] = useState(0);

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  const onLogOut = (event) => {
    event.preventDefault();
    actionLogOut();
    setToken({});
    history.replace('/login')
  }

  const getMessageMe = async () => {
    const res = await api(`auth/getAllMessage?userId=${userId}`);
    setMessagedResp(get(res, 'data.listMessage'));
    setTotalMessage(get(res, 'data.total'))
  };

  useEffect(() => {
    getMessageMe();
  }, [])

  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between" style={
        {
          backgroundImage: 'url(' + require('../../assets/images/bg-banner1.jpeg') + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
        <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt => evt.preventDefault()}><img src={require("../../assets/images/logo-mini.svg")} alt="logo" /></a>
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <i className="mdi mdi-menu"></i>
        </button>
        <ul className="navbar-nav navbar-nav-left header-links align-self-center" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <img src={logoKhcn} style={{ width: 100, height: 100 }} />
          <br />
          <li className="nav-item  d-none  d-md-flex  text-banner" style={{ fontSize: 20, fontWeight: 'bold', lineHeight: 1 }}>
            SỞ KHOA HỌC CÔNG VÀ NGHỆ TỈNH QUẢNG BÌNH
            <br />
            <br />
            BÁO CÁO THỐNG KÊ KHOA HỌC VÀ CÔNG NGHỆ TRỰC TUYẾN
            <br />
            <br />
          </li>
        </ul>

        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item  nav-profile border-0">
            <Dropdown>
              <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                <i className="mdi mdi-email-outline"></i>
                <span className="count">{totalMessage}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="navbar-dropdown preview-list">
                <Dropdown.Item className="dropdown-item  d-flex align-items-center" onClick={evt => evt.preventDefault()}>
                  <p className="mb-0 font-weight-medium float-left">{!isEmpty(messagedLits) ? 'Tất cả thông báo' : 'Bạn chưa có thông báo nào'} </p>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                {messagedLits && messagedLits.map((mess, idx) => {
                  return (
                    <>
                      <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" onClick={() => {
                        routers.push(Routers.NOTIFICATION_DETAIL, { data: mess })
                      }}>
                        <div className="preview-thumbnail">
                          <img src={require("../../assets/images/icon/notification.png")} alt="profile" className="img-sm profile-pic" style={{ width: 18, height: 30 }} /> </div>
                        <div className="preview-item-content flex-grow py-2">
                          <p className="preview-subject ellipsis font-weight-medium text-dark"><Trans>{get(mess, 'message.title')}</Trans> </p>
                          <p className="font-weight-light ellipsis small-text"> <Trans>{get(mess, 'message.content')}</Trans> </p>
                          <p className="font-weight-light small-text"> <Trans>{moment(get(mess, 'message.updatedAt')).format(DATE_FORMAT_DD_MM_YYY)}</Trans> </p>
                        </div>
                      </Dropdown.Item>
                      <div className="dropdown-divider"></div>
                    </>
                  )
                })}

              </Dropdown.Menu>
            </Dropdown>
          </li>


          <li className="nav-item  nav-profile border-0">
            <Dropdown>
              <Link className="nav-link" to={{
                pathname: `/user/profile`,
              }}>
                <img className="img-xs rounded-circle" src={require("../../assets/images/faces/facePlaceHolder.png")} alt="Profile" />
              </Link>
              <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                <Dropdown.Item className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom" href="!#" onClick={evt => evt.preventDefault()}>
                  <div className="d-flex">
                    <div className="py-3 px-4 d-flex align-items-center justify-content-center border-left border-right">
                      <i className="mdi mdi-account-outline mr-0">Xem thông tin</i>
                    </div>
                  </div>
                </Dropdown.Item>

                <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={event => onLogOut(event)}>
                  <Trans>Đăng xuất</Trans>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav >
  );
}

const mapStateToProps = state => ({
  authState: get(state, 'authState'),
});

const mapDispatchToProps = {
  actionLogOut,
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
