import React, { useState, useEffect, useCallback } from 'react'
import { ProgressBar } from 'react-bootstrap';
import Modal from 'react-modal';
import { api, getUserId } from 'app/utils';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT_DD_MM_YYY, Routers } from 'app/constants';
import Spinner from 'app/shared/Spinner';
import { useRouter } from 'app/hooks';

const imgSrc = require('../../assets/images/icon/notification.png');
const imgAdminSrc = require('../../assets/images/icon/admin.png');
const imgClockSrc = require('../../assets/images/icon/clock.png');
const imgTBSrc = require('../../assets/images/icon/thongbao.png');

var perChunk = 2// items per chunk    


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: '#9294a1'
  },

};


function Notifycation(props) {
  const router = useRouter();
  let subtitle;
  const [messagedLits, setMessagedResp] = useState([]);
  const [totalMessage, setTotalMessage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [detailMessSelected, setDetailMessSelected] = useState(false);
  const [dataChunk, setDataChunk] = useState([]);

  const userId = getUserId();

  const getMessageMe = async () => {
    try {
      setIsLoading(true);
      const res = await api(`auth/getAllMessage?userId=${userId}`);
      setMessagedResp(get(res, 'data.listMessage'));
      setTotalMessage(get(res, 'data.total'))
    } catch (e) {

    } finally {
      setIsLoading(false);
    }

  };


  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = 'white';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onOpenDetail = useCallback(async (mess) => {
    setDetailMessSelected(mess);
    router.push(Routers.NOTIFICATION_DETAIL, { data: mess })
  }, []);

  useEffect(() => {
    getMessageMe();
  }, []);


  useEffect(() => {
    if (messagedLits && messagedLits.length > 0) {
      let result = messagedLits.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, []);

      setDataChunk(result);
    }
  }, [messagedLits]);



  if (isLoading) {
    return <Spinner />
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Thông báo </h3>
      </div>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            {/* <h4 className="card-title">Bạn có ({totalMessage}) thông báo</h4> */}

            <div class="container">
              {dataChunk && dataChunk.map(array => {
                return (
                  <div class="row mt-4">
                    {array.map((mess, index) => {
                      const srcImg = get(mess, 'message.urlImageUpload');
                      return (
                        <div class="col-sm" key={get(mess, 'id', index.toString())}>
                          <div class="card p-2 pt-3">
                            <div className="d-flex align-middle align-center">
                              <div className="card-body p-1">
                                <img src={srcImg ? srcImg : imgTBSrc} style={{ width: 130, borderRadius: 4 }} />
                                <br />
                                <button className="mt-1" onClick={() => onOpenDetail(mess)} class="btn btn-link mt-2"><i>Xem chi tiết</i></button>
                              </div>

                              <div class="card-body p-1 pl-2">
                                <div className="d-flex">
                                  <div>
                                    <img src={imgAdminSrc} style={{ width: 14, height: 14 }} />
                                    <span className="ml-1">
                                      <small>Admin</small>
                                    </span>
                                  </div>
                                  <div className="ml-2">
                                    <img src={imgClockSrc} style={{ width: 14, height: 14 }} />
                                    <span className="ml-1">
                                      <small>{moment(new Date(get(mess, 'message.updatedAt._seconds') * 1000)).format(DATE_FORMAT_DD_MM_YYY)}</small>
                                    </span>
                                  </div>
                                </div>
                                <h5 class="mt-1 card-title text-ellipsis">{get(mess, 'message.title')}</h5>
                                <p class="card-text text-ellipsis">{get(mess, 'message.content')}</p>

                              </div>
                            </div>

                          </div>
                        </div>
                      )
                    })
                    }
                  </div>
                )
              })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifycation;
