import React, { useState } from 'react';
// import { Trans } from 'react-i18next';
import { get } from 'lodash';
import PropTypes from 'prop-types';


function SelectCustom(props) {
    const { onAdd, onDelelele, onChangeText, defaultValue = 0, canNotDeleteRow = true } = props || {};
    const [value, setValue] = useState(defaultValue);

    return (
        <div className="d-flex p-2 align-items-center">
            <input class="form-control auto-save-data-user select-country"
                style={{ width: "75%", display: "inline" }}
                value={value.toString()}
                placeholder="-..."
                onChange={(e) => {
                    onChangeText(e.target.value);
                    setValue(e.target.value)
                }}
            />



            <button style={{ backgroundColor: 'transparent', borderWidth: 0, alignItems: 'center' }} onClick={() => onAdd()}>
                <i class="mdi mdi-hospital ml-1" style={{ color: '#094ae0', fontSize: '20px' }}></i>
            </button>

            {!canNotDeleteRow && <button style={{ backgroundColor: 'transparent', borderWidth: 0, alignItems: 'center' }} onClick={() => onDelelele()}>
                <i class="mdi mdi-close-box ml-1" style={{ color: '#eb4034', fontSize: '20px' }}></i>
            </button>}
        </div>

    );

}

export default SelectCustom;

SelectCustom.defaultProps = {
    onAdd: () => { },
    onDelelele: () => { },
    onChangeText: () => { },
}

SelectCustom.propTypes = {
    onAdd: PropTypes.func.isRequired,
    onDelelele: PropTypes.func.isRequired,
    onChangeText: PropTypes.func.isRequired,
    canNotDeleteRow: PropTypes.bool.isRequired,
}