import React, { useState, useEffect } from 'react'
import { ProgressBar } from 'react-bootstrap';
import Modal from 'react-modal';
import { api, getUserId } from 'app/utils';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { DATE_FORMAT_DD_MM_YYY } from 'app/constants';
import Spinner from 'app/shared/Spinner';
import { useRouter } from 'app/hooks';
import { Routers } from 'app/constants';


const imgSrc = require('../../assets/images/icon/notification.png');
const imgAdminSrc = require('../../assets/images/icon/admin.png');
const imgClockSrc = require('../../assets/images/icon/clock.png');
const imgTBSrc = require('../../assets/images/icon/thongbao.png');



function NotifyDetails(props) {
    const { location } = props;
    const router = useRouter();
    const { data } = get(location, 'state', {});
    const srcImg = get(data, 'message.urlImageUpload');
    const srcAttacth = get(data, 'message.urlFileAttachment');
    const nameFileAttacth = get(data, 'message.nameFileAttachment');

    const [isLoading, setIsLoading] = useState(false);


    if (isLoading) {
        return <Spinner />
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Chi tiết thông báo </h3>
            </div>
            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div class="container">
                            <div className="d-flex mt-1">
                                <div>
                                    <img src={imgAdminSrc} style={{ width: 16, height: 16 }} />
                                    <span className="ml-1">
                                        <small>  Admin</small>
                                    </span>
                                </div>
                                <div className="ml-3">
                                    <img src={imgClockSrc} style={{ width: 16, height: 16 }} />
                                    <span className="ml-1">
                                        <small>{moment(new Date(get(data, 'message.updatedAt._seconds') * 1000)).format(DATE_FORMAT_DD_MM_YYY)}</small>
                                    </span>
                                </div>
                            </div>

                            <h5 className="text mt-4 text-dark"><b> {get(data, 'message.title')}</b></h5>
                            <div className="mt-4">
                                {data && srcImg && <img src={srcImg} style={{ width: '80%', height: '60%' }} />}
                                <p className="text mt-3 text-dark"> {get(data, 'message.content')}</p>
                            </div>

                            <div className="mt-3 d-flex">
                                {srcAttacth && <a target="_blank" href={srcAttacth}> <button type="button" className="btn btn-link"  ><i className="text-dark">File đính kèm:</i> {nameFileAttacth}</button></a>}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotifyDetails;
