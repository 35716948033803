import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { actionGetUserMetadata, actionGetAnalysis, actionGetWorkspace, actionGetAllRole, actionGetAllReports } from 'app/redux/actions';
import { withRouter } from 'react-router-dom';
import Spinner from 'app/shared/Spinner';
import { ProgressBar } from 'react-bootstrap';
import { api, getUserId } from 'app/utils';
import { DATA_REPORT_LIST } from 'app/constants'

const options =
{
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true
      }
    }]
  },
  legend: {
    display: false
  },
  elements: {
    point: {
      radius: 0
    }
  }

};


function AdminDashboard(props) {

  const { authState, actionGetUserMetadata, actionGetAnalysis, history, reportsState } = props || {};
  const { isGetttingAnalysis } = reportsState || false;

  const [dataLabelsDashboard, setDataLabelsDashboard] = useState([]);
  const [dataNumbersDashboard, setDataNumbersDashboard] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const userId = getUserId();

  const fetchDashboardByYear = async () => {
    const res = await api('admin/dashboardAllYear', {
      userId: 'admin',
      securityKey: 'KHCNQB'
    });

    const { data } = res;
    let labels = [], numbers = []
    const dataAPI = get(data, 'data', []);
    dataAPI.map(item => {
      labels.push(get(item, 'year'));
      numbers.push(get(item, 'avg', 0).toFixed(2))
    });
    setDataLabelsDashboard(labels);
    setDataNumbersDashboard(numbers);
  }

  const fetchDashboardByReport = async () => {
    try {
      setLoading(true);
      const res = await api('admin/dashboardByReport', {
        userId: 'admin',
        securityKey: 'KHCNQB',
        year: 2021
      });

      const { data } = res;
      const dataAPI = get(data, 'data', []);
      let dataChart = [];
      dataAPI.map((item, idx) => {
        const { id, average } = item || {};
        const findTitle = DATA_REPORT_LIST.find(rp => rp.id === id);
        const { title } = findTitle || '';
        dataChart.push(
          {
            id,
            average,
            title
          }
        )
      });

      setDataTable(dataChart);
    } catch (e) {

    }
    finally {
      setLoading(false);
    }

  }


  useEffect(() => {
    actionGetUserMetadata(userId);
    actionGetWorkspace(userId);
    actionGetAllRole(userId);
    actionGetAllReports(userId);
    fetchDashboardByYear();
    fetchDashboardByReport();
  }, []);

  const dataBarChart = {
    labels: dataLabelsDashboard,
    datasets: [{
      label: '% Hoàn thành',
      data: dataNumbersDashboard,
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1,
      fill: false,
      barPercentage: 0.5,
      stack: 'fafd'
    }]
  };

  const options = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0
      }
    },

  };


  if (isGetttingAnalysis || isLoading) {
    return <Spinner style={{ position: null }} />;
  }

  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">
          Tổng quan số liệu
        </h3>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Thống kê hoàn thành dữ liệu theo năm</h4>
              <Bar data={dataBarChart}
                options={options}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Thống kê số liệu theo báo cáo</h4>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Tên biểu </th>
                      <th> Tiến độ </th>
                      <th> Số % </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataTable && dataTable.map(item => {
                      return (
                        <tr>
                          <td>{get(item, 'title')} </td>
                          <td>
                            <ProgressBar variant="success" now={get(item, 'average', 0)} />
                          </td>
                          <td> {get(item, 'average', 0) ? get(item, 'average', 0).toFixed(2) : 0}% </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportsState: get(state, 'reportState'),
});

const mapDispatchToProps = {
  actionGetUserMetadata,
  actionGetAnalysis,
  actionGetWorkspace,
  actionGetAllReports
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDashboard))
