import React, { useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import Spinner from 'app/shared/Spinner';
import moment from 'moment';
import { DATA_REPORT_LIST, Routers, DATE_FORMAT_DD_MM_YYY } from 'app/constants';
import { getUserId } from 'app/utils';
import { api } from 'app/utils';
import { KEY_SECURITY } from 'app/configs';
import { useRouter } from 'app/hooks';

function ManagementUser(props) {

  const routers = useRouter();

  const userId = getUserId();

  const [listDataUsers, setLisDataUser] = useState([]);
  const [loading, setIsloading] = useState(false);

  const fetchUsers = async () => {
    try {
      setIsloading(true);
      const resp = await api(`admin/users?userId=${userId}&securityKey=${KEY_SECURITY}`);
      const users = get(resp, 'data.data', []);
      if (users) {
        setLisDataUser(users)
      }
    } catch (err) {
    } finally {
      setIsloading(false);
    }
  };

  const onAddUser = () => {
    routers.push(Routers.ADMIN_USER_ADD, { userDetail: {} });
  }


  useEffect(() => {
    fetchUsers();
  }, []);

  if (loading) {
    return (
      <Spinner />
    )
  }

  return (
    <div className="col-lg-12 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Danh sách tài khoản</h2>
          <div className="table-responsive">
            <table className="table table-bordered bordered">
              <thead>
                <tr>
                  <th> # </th>
                  <th>Họ & Tên  </th>
                  <th> Đơn vị </th>
                  <th> Tài khoản </th>
                  <th> Quyền </th>
                  <th> Ngày tạo </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>

                {!isEmpty(listDataUsers) && listDataUsers.map((user, idx) => {
                  return (
                    <tr>
                      <td> {idx + 1} </td>
                      <td><Link className="nav-link"
                        to={{
                          pathname: Routers.ADMIN_USER_DETAIL,
                          state: { userDetail: user }
                        }}><b className="text-primary">{get(user, 'fullName', '')}</b>  </Link></td>
                      <td> {get(user, 'work.name', '')}</td>
                      <td> {get(user, 'userName', '')}</td>
                      <td> {get(user, 'role.desc', '')}</td>
                      <td> {moment(get(user, 'created', moment())).format(DATE_FORMAT_DD_MM_YYY)}</td>
                      <td> <Link className="nav-link"
                        to={{
                          pathname: Routers.ADMIN_USER_DETAIL,
                          state: { userDetail: user }
                        }}><i class="mdi mdi-eye text-primary"></i></Link> </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>

            <button type="submit" className="btn btn-primary mt-3" style={{ width: 150, height: 50 }} onClick={() => onAddUser()}>Thêm tài khoản</button>

          </div>
        </div>
      </div>
    </div>
  )


}

const mapStateToProps = state => ({
  authState: get(state, 'authState'),
  reportsState: get(state, 'reportState'),
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementUser))
