import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import bsCustomFileInput from 'bs-custom-file-input';
import { api } from 'app/utils';
import { get, isEmpty } from 'lodash';
import { getUserId } from 'app/utils';
import { actionLogOut, actionGetUserMetadata, actionGetWorkspace, actionGetAllRole, actionGetAllReports } from 'app/redux/actions';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useToken, useRouter } from 'app/hooks';
import Spinner from 'app/shared/Spinner';
import { Routers } from 'app/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { AdminSendMessage } from 'app/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { KEY_SECURITY } from 'app/configs';
import storage from 'app/firebase';

const MAX_SIZE_FILE_ATTACHMENT = 1024 * 1024 * 5; //MiB
const MAX_SIZE_FILE_IMAGE = 1024 * 1024 * 2; //MiB

const onFileUpload = async (file, type) => {
    try {
        if (file == null) {
            return;
        }
        const result = await storage.ref(`/messages/${file.name}`).put(file);
        console.log('result', result)
        const { ref } = result;
        const url = await ref.getDownloadURL();
        return { type, url, fileName: file.name };
    } catch (e) {
        console.log('onFileUpload', e)
    }
};


function ManagementUserDetail(props) {
    const { history,
        authState,
        workSpaces,
        actionGetWorkspace,
        gettingWorkspace,
        listRoles,
        gettingRoles,
        actionGetAllRole,
        listReports,
        gettingReports,
        actionGetAllReports,
        location
    } = props;

    const [isLoading, setIsloading] = useState(false);
    const [listUser, setListUser] = useState([]);
    const [isSendAll, setIsSendAll] = useState(false);
    const [userSelected, setUserSelected] = useState('');
    const [selectedFileAttach, setSelectedFileAttach] = useState(null);
    const [selectedImgSrc, setSelectedImgSrc] = useState(null);
    const [image, setImage] = useState(null)

    const userId = getUserId();

    const routers = useRouter();

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(AdminSendMessage)
    });

    const fetchUsers = async () => {
        try {
            setIsloading(true);
            const resp = await api(`admin/users?userId=admin&securityKey=${KEY_SECURITY}`);
            const users = get(resp, 'data.data', []);
            if (users) {
                setListUser(users)
            }
        } catch (err) {
        } finally {
            setIsloading(false);
        }
    };


    const [form, setForm] = useState({
        title: '',
        content: '',
    });

    const onChangeTextField = (field, event) => {
        setForm({ ...form, [field]: event.target.value })
    };

    const onFileImageChange = event => {
        if (event.target.files[0].size > MAX_SIZE_FILE_IMAGE) {
            toast.warning("Ảnh có kích thước lớn, Bạn cần chọn ảnh có dung lượg nhỏ hơn 2MB!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        const imageSrc = event.target.files[0];
        setSelectedImgSrc(imageSrc);
        setImage(URL.createObjectURL(imageSrc));
    };

    const onFileAttachmentChange = event => {
        const file = event.target.files[0];
        if (event.target.files[0].size > MAX_SIZE_FILE_ATTACHMENT) {
            toast.warning("File có kích thước lớn, Bạn cần chọn file có dung lượg nhỏ hơn 5MB!", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        setSelectedFileAttach(file);
    };




    const onSubmit = async () => {
        try {
            setIsloading(true);
            let tasks = [];
            if (selectedFileAttach) {
                tasks.push(onFileUpload(selectedFileAttach, 'attachment'))
            }
            if (selectedImgSrc) {
                tasks.push(onFileUpload(selectedImgSrc, 'image'))
            };

            const uploadFileDone = await Promise.all(tasks);
            const imageUploaded = uploadFileDone.find(item => item.type === 'image') || {};
            const fileUploaded = uploadFileDone.find(item => item.type === 'attachment') || {};

            let receivers = [];
            if (isSendAll) {
                listUser.map(item => {
                    receivers.push(get(item, 'userId'))
                });
            } else {
                receivers.push(userSelected)
            }

            let bodyReq = {
                ...form,
                receivers,
            };
            if (imageUploaded) {
                bodyReq.urlImageUpload = get(imageUploaded, 'url')
            }
            if (fileUploaded) {
                bodyReq.urlFileAttachment = get(fileUploaded, 'url');
                bodyReq.nameFileAttachment = get(fileUploaded, 'fileName');
            }

            const res = await api('admin/sendMessage', bodyReq, true);
            if (get(res, 'data.data.message')) {
                toast.success("Gửi thành công!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        } catch (e) {
            console.log('send message error', e)
        } finally {
            setIsloading(false);
        }
    }

    useEffect(() => {
        fetchUsers();
    }, [])



    if (isLoading) {
        return <Spinner />
    }

    return (
        <div>
            <div className="page-header">
                <h3 className="page-title"> Gửi thông báo</h3>
            </div>
            <div className="row">
                <div className="col-md-10 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample mt-4"
                                onSubmit={handleSubmit(onSubmit)}
                            >

                                <div class="form-group"><label for="title">Tiêu đề</label>
                                    <input placeholder="Tiêu đề thông báo" type="text" id="title" class="form-control" aria-autocomplete="list"
                                        {...register("title")}
                                        value={form.title}
                                        onChange={event => onChangeTextField("title", event)}
                                    />
                                    <p class="invalid-validate">{errors.title?.message}</p>
                                </div>

                                <div class="form-group"><label for="content">Nội dung</label>
                                    <textarea placeholder="Nội dung" type="textarea" id="content" class="form-control" aria-autocomplete="list"
                                        rows={7}
                                        {...register("content")}
                                        value={form.content}
                                        onChange={event => onChangeTextField("content", event)}
                                    />
                                    <p class="invalid-validate">{errors.content?.message}</p>
                                </div>

                                <div class="form-group"><label for="content">Ảnh bìa</label>
                                    <label htmlFor="upload-file-img" class="btn btn-link btn-sm ml-2 mb-2" ><i class="mdi mdi-laptop"  ></i> Chọn ảnh</label>
                                    <input style={{ display: "none" }} type="file" id="upload-file-img" onChange={(e) => onFileImageChange(e)} accept="image/png, image/gif, image/jpeg" disabled={userId !== 'admin'} />
                                    {image && <img className="ml-2" src={image} alt="preview image" style={{ width: 300, height: 200 }} />}
                                </div>


                                <div class="form-group"><label for="content">File đính kèm</label>
                                    <label htmlFor="upload-file" class="btn btn-link btn-sm ml-2 mb-2" ><i class="mdi mdi-laptop"  ></i> Chọn file</label>
                                    <input style={{ display: "none" }} type="file" id="upload-file" onChange={(e) => onFileAttachmentChange(e)} disabled={userId !== 'admin'} />
                                    {selectedFileAttach && <span className="ml-2">{selectedFileAttach?.name}</span>}
                                </div>



                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" onChange={(e) => {
                                            setIsSendAll(e.target.checked);
                                            setUserSelected(null);
                                        }} />
                                        <i className="input-helper"></i>
                                        Gửi toàn bộ
                                    </label>
                                </div>

                                {!isSendAll && <div class="form-group d-flex p-2">
                                    <label className="mr-2" for="controlRecive" style={{ width: 120 }}>Người nhận</label>
                                    <select class="form-control" id="controlRecive"
                                        value={userSelected} onChange={(event) => setUserSelected(event.target.value)}
                                    >
                                        {listUser.map((item, idx) => {
                                            return (<option value={get(item, 'userId')} key={get(item, 'userId')}>{get(item, 'fullName')}</option>)
                                        })}


                                    </select>
                                </div>}

                                <button type="submit" className="btn btn-primary mr-2" style={{ width: 100, height: 50 }}>Gửi</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    authState: get(state, 'authState'),
    userInfo: get(state, 'authState.userInfo.data', {}),
    workSpaces: get(state, 'authState.workSpaces.data', []),
    gettingWorkspace: get(state, 'authState.gettingWorkspace'),
    listRoles: get(state, 'authState.roles.data', []),
    gettingRoles: get(state, 'authState.gettingRoles'),
    listReports: get(state, 'authState.listReports.data', []),
    gettingReports: get(state, 'authState.gettingReports'),
});

const mapDispatchToProps = {
    actionLogOut,
    actionGetUserMetadata,
    actionGetWorkspace,
    actionGetAllRole,
    actionGetAllReports
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagementUserDetail))
