import { ActionsType } from 'app/redux/actions';
import { api } from 'app/utils';
import { get } from 'lodash';

export const actionGetAnalysis = reqData => async dispatch => {
    const { userId, year } = reqData || '';
    dispatch({ type: ActionsType.GET_ANALYSIS_REQUEST })

    try {
        const res = await api(`reports/getAnalysisByUser?userId=${userId}&year=${year}`);
        const { data } = res || {};
        if (get(data, 'data')) {
            dispatch({ type: ActionsType.GET_ANALYSIS_SUCCESS, payload: data });
        } else {
            dispatch({ type: ActionsType.GET_ANALYSIS_FAIL, payload: data });
        }
        return res;
    } catch (ex) {
        dispatch({ type: ActionsType.GET_ANALYSIS_FAIL, payload: ex.message });
        throw ex;
    }
};


export const actionGetStorageReportDetail = reqData => async dispatch => {
    dispatch({ type: ActionsType.GET_STORAGE_REPORT_DETAIL_REQUEST })
    try {
        const res = await api(`reports/viewtemplate`, reqData, true);
        const { data } = res || {};
        if (get(data, 'data')) {
            dispatch({ type: ActionsType.GET_STORAGE_REPORT_DETAIL_SUCCESS, payload: data });
        } else {
            dispatch({ type: ActionsType.GET_STORAGE_REPORT_DETAIL_FAIL, payload: data });
        }
        return res;
    } catch (ex) {
        dispatch({ type: ActionsType.GET_STORAGE_REPORT_DETAIL_FAIL, payload: ex.message });
        throw ex;
    }
};

export const actionLoadingUploadFile = () => async dispatch => {
    dispatch({ type: ActionsType.LOADING_UPLOAD_FILE })
};


export const actionEndedLoadingUploadFile = () => async dispatch => {
    dispatch({ type: ActionsType.ENDED_LOADING_UPLOAD_FILE })
};
